.site-notice {
    background: rgb(var(--t-bg--action));
    color: rgb(var(--t-content));
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0.75rem 1.5rem 0.75rem 2rem;

    &__link {
        text-decoration: none;
        padding: 1rem 2rem;
        background: rgb(var(--t-btn-bg));
        border: solid 1px;
        border-color: rgb(var(--t-btn-border));
        color: rgb(var(--t-btn-content));

        &:hover,
        &:focus {
            background: rgb(var(--t-bg--action));
            border-color: rgb(var(--t-btn-border--action));
            color: rgb(var(--t-content--action));
        }
    }

    &__close {
        flex-shrink: 0;
        width: 2rem;
        background: transparent;
        padding: 0;
        margin: 0;
        border: none;

        > svg {
            width: 100%;

            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-in-out;
                
            }
        }

        &:hover,
        &:focus {

            .theme-icon-bg {
                fill: rgb(var(--t-linkbutton-icon-bg--action));
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content--action));
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content--action));
                
            }

        }
    }

    &__content {
        > *:last-child {
            margin-bottom: 0;
        }
    }

    // Position Modifiers
    &--default {
        // Code
    }

    &--top {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
    }

    &--bottom {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.display-none {
    display: none;
}