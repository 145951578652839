/* ======================================== */
/* image-placeholder */
/* ======================================== */

.image-placeholder {
    position: relative;
    padding-top: 66.667%;

    &__content {
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20%; // Reduce the size of svg

        > svg {
            width: 3rem;
        }
    }

    .logo-placeholder-theme-bg {
        fill: rgb(var(--t-content));
        transition: fill 0.3s 0s ease-in-out;
    }
}