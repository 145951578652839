/* ======================================== */
/* typography */
/* ======================================== */

@use '../abstracts' as *;

html {
    font-size: 1em;     // 16px (default value for browsers)
    line-height: 1.5;   // 24px
}

body {
	font-family: $font-stack;
}

h1,h2,h3,h4,h5,h6,
.alpha, .beta, .gamma, .delta, .epsilon, .zeta {
 	font-weight: normal;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0 0 0.5em 0;
}

h1, .alpha,
h2, .beta {
	font-size: 1.5rem;          // 28px -> 24px
	line-height: 1.125;
    font-family: $font-stack-alt;

	@include breakpoint(sm) {
		font-size: 2rem;          // 32px
	}

	@include breakpoint(lg) {
		font-size: 2.5rem;          // 40px
	}

}

h3, .gamma {
	font-size: 1.25rem;         // 20px
	line-height: 1.25;
}

h4, .delta {
	font-size: 1rem;            // 16px
	line-height: 1.25;
	font-family: $font-stack-alt;
}

h5, .epsilon {
	font-size: 1rem;            // 16px
	line-height: 1.25;
	font-family: $font-stack-alt;
}

h6, .zeta {
	font-size: 1rem;            // 16px
	line-height: 1.25;
	font-family: $font-stack-alt;
}


p,
.p {
	font-size: 1rem; // 18px

    &.alpha,
    &.beta,
    &.gamma,
    &.delta,
    &.epsilon,
    &.zeta {
		line-height: 1.25;
    }
}

p, li, label {
	margin: 0 0 1em 0;
	line-height: 1.5;
}

.text-muted		{opacity: 0.6;}

.primary-heading {
	color: rgb(var(--t-content--action));

	.text-highlight {
		color: rgb(var(--t-content));
	}
}

/* --------------------------------------------------------------------------------------------*/
/* SITE SPECIFIC TYPOGRAPHY */
/* --------------------------------------------------------------------------------------------*/

strong {
	font-family: $font-stack-alt;
}

a {
	text-decoration: none;
}

sub, sup {
	font-size: 80%;
}

sup {
	top: -1em;
}

/* -----------------------------*/
/* FONT */

.text-sans-serif {
	font-family: $font-stack;
}

/* -----------------------------*/
/* PROMINENCE */

.text-highlight {
	color: rgb(var(--t-content--action));
}


/* -----------------------------*/
/* STYLE */

.text-uppercase {
	text-transform: uppercase;
}

.text-nocase {
	text-transform: none;
}

/* -----------------------------*/
/* SIZE */

.text-large {
	display: inline-block;
	line-height: 1.125em;
	font-size: 1.5em;

	@include breakpoint(md) {
		font-size: 2em;
	}
}

.text-small {
	font-size: 0.75em;
	line-height: 1em;
	display: inline-block;
}

.text-tiny {
	font-size: 0.5em;
	line-height: 1em;
	display: inline-block;
}

/* -----------------------------*/
/* WEIGHT */

.text-light {
	font-family: $font-stack;
	font-weight: 400;
}

.text-regular {
	font-family: $font-stack;
    font-weight: bold;
}

.text-bold {
    font-family: $font-stack-alt;
}


a {
    color: rgb(var(--t-link));

    &:hover,
    &:focus {
        color: rgb(var(--t-link--alt-action));
    }
}

/* -----------------------------*/
/* UNNEXPECTED USER ERRORS */

br + br {
	display: none; // Assuming that we don't need any more than 2
}