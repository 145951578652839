/* ======================================== */
/* quote */
/* ======================================== */

@use 'abstracts' as *;

.quote {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 2em;
    margin-top: 4rem;
    margin-bottom: 4rem;
    max-width: 70ch;

    > blockquote {
        display: block;
        position: relative;
        quotes: "“" "”";

        &:before {
            position: relative;
            display: block;
            font-size: 5rem;
            line-height: 1; // fiddly with this character because it has lots of white space beneath it
            font-family: $font-stack-alt;
            color: rgb(var(--t-content--action));
        }

        &:before {
            // content: "\201C";
            content: open-quote;
            text-align: left;
            margin-bottom: -0.35em;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0.5rem;
        height: 100%;
        background-color: rgb(var(--t-highlight));
    }

    &__content {
        color: rgb(var(--t-content--action));
        font-family: $font-stack-alt;
    }

    &__author {
        color: rgb(var(--t-content));

        &-name {
            font-weight: bold;
        }
    }

    &__company {
        color: rgb(var(--t-content));
    }
}