/* ======================================== */
/* eyebrow */
/* ======================================== */

@use 'abstracts' as *;

.eyebrow-heading {
    text-transform: uppercase;
    position: absolute;
    right: calc(100% - 3em);
    height: 100%;
    pointer-events: none;
    font-size: 0.625rem;
    font-family: $font-stack-alt;
    letter-spacing: 0.25em;
    z-index: 2;

    @include breakpoint(sm) {
        font-size: 0.75rem;
        font-family: $font-stack;
    }

    &__inner {
        position: sticky;
        top: 0;
        left: 0;
        display: inline-block;
        white-space: nowrap;
        padding: 0.25em 1em;
        background: rgb(var(--t-bg--action));
        color: rgb(var(--t-content));
        transform: rotate(-90deg) translateX(1em) translateY(-100%);
        transform-origin: right center;
    }
}