/* ======================================== */
/* tag-list */
/* ======================================== */

.tag-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 0.25em;

    &__tag {
        font-size: 0.5rem;
        padding: 0.375em 0.75em 0.25em 0.75em;
        font-weight: bold;
        line-height: 1;
        background: rgb(var(--t-bg--action));
        border-radius: 0.25em;
        text-transform: uppercase;
        letter-spacing: 0.125em;
        transition: all 0.3s 0s ease-in-out;
    }
}