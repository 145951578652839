/* ======================================== */
/* btn */
// looks like a button but isn't a <button> element
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

$button-border-left-thickness: 1em;
$button-botder-radius-size: 0.375em;
$button-chevron-thickness: 0.175em;
$button-transition-timing: 0.3s;

@keyframes chevron-pulse {
    0% {
        transform: translateX(0) rotate(-45deg);
    }
    50% {
        transform: translateX(math.div($button-border-left-thickness,2)) rotate(-45deg);
    }
    100% {
        transform: translateX(0) rotate(-45deg);
    }
}

.btn {
    position: relative;
    display: inline-flex;
    background-color: rgb(var(--t-btn-bg));
    color: rgb(var(--t-btn-content));
    padding: 
        math.div($button-border-left-thickness,2) 
        ($button-border-left-thickness*1.5) 
        math.div($button-border-left-thickness,2) 
        ($button-border-left-thickness*2);
    border-radius: $button-botder-radius-size;
    text-decoration: none;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.15);
    font-family: $font-stack-alt;

    align-items: center;
    justify-content: space-between;
    transition: all $button-transition-timing 0s ease-in-out;

    span {
        position: relative;
        z-index: 2;
        transition: all $button-transition-timing 0s ease-in-out;
        line-height: 1.25em;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: $button-border-left-thickness;
        z-index: 1;
        border-radius: $button-botder-radius-size 0 0 $button-botder-radius-size;
        background-color: rgb(var(--t-btn-border));
        transition: all $button-transition-timing 0s ease-in-out;
    }

    &:after {
        flex-shrink: 0;
        flex-grow: 0;
        position: relative;
        content: "";
        width: 0.5em;
        height: 0.5em;
        margin-left: 0.5em;
        border-width: $button-chevron-thickness;
        border-color: 0 rgb(var(--t-btn-content)) rgb(var(--t-btn-content)) 0;
        border-style: solid;
        transform: rotate(-45deg);
        transform-origin: center center;
        z-index: 2;
        transition: all $button-transition-timing 0s ease-in-out;
    }

    &:hover,
    &:focus {

        &:before {
            width: 100%;
            border-radius: $button-botder-radius-size;
            background-color: rgb(var(--t-btn-border--action));
            transform: scale(1.05);
            box-shadow: 0 0.25rem 2rem 0 rgba(0, 0, 0, 0.25);
        }

        span {
            color: rgb(var(--t-btn-content--action));
        }

        &:after {
            border-color: 0 rgb(var(--t-btn-content--action)) rgb(var(--t-btn-content--action)) 0;
            animation: chevron-pulse infinite $button-transition-timing*2 ease-in-out;
        }
    }

}