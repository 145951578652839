/* ======================================== */
/* formbuilder */
// designed to work with FormBuilder markup
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

// label for communication preferences fieldset
.InputfieldFieldset legend {
    font-weight: bold;
}

label {
    font-weight: normal;
}

/* -------------------------------------*/
/* Formbuilder element wrappers */

.Inputfield {
    position: relative;
    display: block;
    margin-top: $input-padding*1.5;

    @include breakpoint(mobile-max) {
        width: 100% !important;
    }
}


.InputfieldColumnWidth {
    display: inline-block;
}

.InputfieldContent {
    position: relative;

    .form-item-group & {
        margin-top: 0;
    }

    .description {
        margin-bottom: math.div($input-padding, 2);
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}

.InputfieldContent .error {
    display: inline-block;
    line-height: 100%;
    background: red;
    color: $white !important;
    padding: 0.25em 0.5em;
    font-weight: 500;
    font-size: 0.75em;
    margin-bottom: 1em;

    li:last-child {
        margin-bottom: 0;
    }
}


/* -------------------------------------*/
/* Textbox */

.InputfieldText,
.InputfieldSelect,
.InputfieldTextarea,
.InputfieldEmail,
.InputfieldUpload {

    .InputfieldHeader {
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.3s 0s;
        opacity: 0;
        font-size: 0.75em;
        color: rgb(var(--t-content));
        z-index: 1;

        .toggle-icon {
            display: none;
        }
    }

    .InputfieldHeader.show {
        opacity: 1;

        &.on {
            color: red;
        }
    }
}

.InputfieldTextarea {
    .InputfieldHeader {
        // bottom: -$input-padding*0.25;
    }
}


/* -------------------------------------*/
/* Hidden field */

.InputfieldHidden {
    display: none;
}

/* -------------------------------------*/
/* Control group */

//checkbox and radio groups
.InputfieldCheckbox,
.InputfieldCheckboxes,
.InputfieldRadios {
    display: block;
    position: relative;
    padding-bottom: 0;
    margin: $input-padding 0 $input-padding*2;
    font-size: $form-font-size;

    // Remove * that gets added to after on required checkbox... Yeah, I know, sorry!
    &.InputfieldStateRequired > .InputfieldContent > label > span.pw-no-select::after {
        content: "" !important;
        margin-left: 0 !important;
    }

    // Label that contains the input (I know!!!!)
    label {
        position: relative;
        margin: 0;
        cursor: pointer;
        display: block;
    }

    // Info label only (I know!!!!)
    .InputfieldHeader {
        position: relative; //override base styling
        font-size: 1em; //override base styling
        top: 0; //override base styling
        left: 0; //override base styling
        font-weight: normal;
        font-weight: bold;
        margin-bottom: $input-padding;
    }

    // The actual input
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        // Checked
        &:checked ~ .pw-no-select {

            &:before {
                background: rgb(var(--t-form-input-bg--action));
            }

            &:after {
                display: block;
            }
        }

        // Disbled
        &:disabled ~ .pw-no-select {

            &:before {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        // Hover
        &:hover ~ .pw-no-select:before,
        &:focus ~ .pw-no-select:before {
            border-color: rgba(var(--t-form-input-border--action), 0.1);
        }
    }

}

// label containing checkbox / radio button
.pw-no-select {
    cursor: pointer;

    // The checkbox box / radio circle
    &:before {
        content: '';
        position: relative;
        display: inline-block;
        vertical-align: middle;
        height: $radio-checkbox-size;
        width: $radio-checkbox-size;
        background-color: rgba(var(--t-form-input-bg), 0.1);
        border: 1px solid rgba(var(--t-form-input-border), 0.1);
        margin-right: math.div($input-padding, 2);
        transition: all 0.2s 0s;

        .InputfieldRadios & {
            border-radius: 50%;
        }
    }

    // The tick that appears when selected
    &:after {
        content: "";
        position: absolute;
        top: math.div($radio-checkbox-size, 2);
        left: math.div($radio-checkbox-size, 2);
        width: math.div($radio-checkbox-size, 4);
        height: math.div($radio-checkbox-size, 2);
        border-style: solid;
        border-width: 0 2px 2px 0;
        border-color: rgb(var(--t-form-input-content));
        transform: translateX(-(math.div(-$radio-checkbox-size, 7.5))) translateY(-(math.div($radio-checkbox-size, 4))) rotate(45deg);
        transform-origin: center center;
        display: none;
    }
    
}



/* -------------------------------------*/
/* Select */

$select-arrow-size: 0.5rem;

.InputfieldSelect {
    position: relative;
    display: inline-block;
    // margin-bottom: 2em;
    width: 100%;

    .InputfieldHeader {
        opacity: 1;
    }

    .InputfieldContent {
        position: relative;
    }

    select {
        display: inline-block;
        cursor: pointer;
        outline: none;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        margin-bottom: 0;
        padding-right: $input-padding*2; // Chrome and safari seem to need twice as much padding here

        &::-ms-expand {
            display: none;
        }

        &:hover,
        &:focus {
            outline: none;
        }

        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    
        option {
            padding: $input-padding;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    // Arrow
    .InputfieldContent:after {
        content: "";
        z-index: 1;
        position: absolute;
        bottom: 50%;
        right: $input-padding;
        pointer-events: none;

        width: $select-arrow-size;
        height: $select-arrow-size;
        margin-top: -(math.div($select-arrow-size, 2));
        margin-left: -(math.div($select-arrow-size, 2));
        border-style: solid;
        border-width: $input-border-width $input-border-width 0 0;
        transform: translateY(50%) rotate(135deg);
    }
}




/* -------------------------------------*/
/* Submit button */

.InputfieldSubmit {
    border-top: 1px solid;
    padding-top: 2em !important;
    margin-top: 2em !important
}

/* -------------------------------------*/
/* Error / Success */

// This knocks a lot of things out of whack with position absolute so we just set this to 0
.maxColHeightSpacer {
    height: 0 !important;
}

.error {
    color: $red !important;
}

.FormBuilderErrors {
    margin-bottom: $input-padding;
}

.success {
    background: white;
    color: black;
    padding:2em;
    border: 1px solid black;
    font-size:1.25em;
}

.FormBuilder {
    .notes {
        background: yellow;
        padding: 0.25rem 0.75rem 0.35rem 0.75rem;
        line-height: 1.25;
        color: black;
    }
}