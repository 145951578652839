/* ======================================== */
/* Company Social Media Cards */
/* ======================================== */
@use 'abstracts' as *;

$card-transition-duration: 0.3s;

.social-media-grid {
    display: grid;
    gap: 2rem;

    @include breakpoint(sm) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.social-media-card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: rgb(var(--t-bg));
    color: rgb(var(--t-content));
    border: solid 1px rgba(var(--t-content), 0.1);
    transition: all $card-transition-duration 0s ease-out;
    

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: -1px;
        left: -1px;
        width: 0;
        height: calc(100% + 2px);
        transition: width $card-transition-duration 0s ease-out;
        background-color: rgb(var(--t-highlight));
    }

    &:hover,
    &:focus {
        color: var(--t-content);
        box-shadow: 0.25rem 0.25rem 1rem 0 rgb(0, 0, 0, 25%);
        border-color: none;
        transform: translateY(-3px);

        &:before {
            width: 0.5rem;
        }

        .social-media-card__icon {
            > svg {
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }
    }

    &__icon-social {
        width: 1.5rem;
        align-self: flex-end;
        margin-bottom: 2.5rem;
    }

    &__heading {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1.25rem;
    }

    &__description {

    }

    &__icon {
        display: block;
        width: 2rem;
        align-self: flex-end;
        margin-top: auto;

        > img,
        > svg {
            width: 100%;
        }

        > svg {
            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-in-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-in-out;
            }
        }
    }
}