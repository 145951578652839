/* ======================================== */
/* hero-understated */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

$hero-understated-dot-size: 1rem;

.hero-understated {
    width: 100%;
    display: block;
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    &__inner {
        width: 100%;
        display: grid; // Part fix (1) for miscalculated slide width
    }

    &__controls {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        margin-top: 2rem;

        &-dots { // override default slick style of circle dots
            position: relative;
            bottom: auto;
            z-index: 80;
            width: 100%;
            height: auto;
            width: auto;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            li {
                margin: 0 0 0 math.div($hero-understated-dot-size, 4);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                @include breakpoint(xs) {
                    margin: 0 0 0 math.div($hero-understated-dot-size, 2);
                }

                &:first-child {
                    margin-left: 0;
                }

                &.slick-active {

                    button {
                        background-color: rgb(var(--t-linkbutton-icon-bg--action));
                    }
                }
            }

            button {
                width: math.div($hero-understated-dot-size, 2);
                height: math.div($hero-understated-dot-size, 2);
                border-radius: $hero-understated-dot-size;
                background-color: transparent;
                border: 1px solid rgb(var(--t-linkbutton-icon-bg--action));
                font-size: 1px;
                text-indent: -99999px;

                @include breakpoint(xs) {
                    width: $hero-understated-dot-size;
                    height: $hero-understated-dot-size;
                }
            }
        }
    }
    
    &__carousel {
        width: 100%;
        display: block;
        min-width: 0; // Part fix (1) for miscalculated slide width
        min-height: 0; // Part fix (1) for miscalculated slide width

        &-item {
            position: relative;
            width: 100%;

            &-inner {
                // slick slider attatches its own classes to the item so this ensures we have our own space to play with layout
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 2rem;

                // Modifiers - left
                // default and bottom don't apply in this cms context so default them too
                &--left,
                &--default,
                &--bottom {
                    @include breakpoint(sm) {
                        flex-direction: row-reverse;
                    }
                }
                
                // Modifiers - right
                &--right {
                    @include breakpoint(sm) {
                        flex-direction: row;
                    }
                }
            }

            &-media {
                position: relative;
                width: 100%;

                @include breakpoint(sm) {
                    flex-basis: 50%;
                }
            }

            &-picture {
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: block;
            }

            &-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform-origin: center;
                transition: transform 3s 0s ease-in-out;
            }

            &-iframevideo {
                top: 0;
                left: 0;
                position: relative;
                padding-top: 66.666666%; // 3:2 ratio
                width: 100%;
                overflow: hidden;

                @include breakpoint(md) {
                    padding-top: 100%; // 1:1 ratio
                }

                &__iframe {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    height: 100%;
                    width: 177%;
                    transform: translateX(-50%);
                }
            }

            &-uploadvideo {
                position: relative;
                width: 100%;
                padding-top: 66.666666%; // 3:2 ratio

                @include breakpoint(md) {
                    padding-top: 100%; // 1:1 ratio
                }

                &__video {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-content {
                position: relative;
                z-index: 3;

                @include breakpoint(sm) {
                    flex-basis: 50%;
                }

                & *:first-child {margin-top: 0;}
                & *:last-child {margin-bottom: 0;}
            }

        }


        $hero-carousel-prevnext-size: 2rem;

        &-prev {
            transform: rotate(180deg);
        }

        &-prev,
        &-next {
            // display: none;
            z-index: 3;
            top: 50%;
            transform-origin: center center;
            width: $hero-carousel-prevnext-size;
            background: transparent; // override base button style
            border: none;  // override base button style
            line-height: 0;  // override base button style
            padding: 0;  // override base button style
            transition: all 0.3s 0s ease-in-out;

            &.slick-disabled {
                opacity: 0;
            }

            > svg {
                width: 100%;

                .theme-icon-bg {
                    fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                    transition: fill 0.3s 0s ease-out;
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content));
                    transition: fill 0.3s 0s ease-out;
                }
                .theme-icon-content-stroke {
                    fill: rgb(var(--t-linkbutton-icon-content));
                    transition: stroke 0.3s 0s ease-out;
                }
            }

            &:hover,
            &:focus {

                > svg {
    
                    .theme-icon-bg {
                        fill: rgb(var(--t-linkbutton-icon-bg--action));
                    }
                    .theme-icon-content-fill {
                        fill: rgb(var(--t-linkbutton-icon-content--action));
                    }
                    .theme-icon-content-stroke {
                        fill: rgb(var(--t-linkbutton-icon-content--action));
                    }
                }

            }
        }

    }
}

// Slick Slider overrides
.jsHeroUnderstatedCarousel {

    .hero-understated__carousel-item-media,
    .hero-understated__carousel-item-content {
        opacity: 0;
        transform: translateX(-2vh);
        transition-property: transform, opacity;
        transition-timing-function: ease-in-out;
        transition-duration: 0.5s;
        transition-delay: 0s;
    }
    .hero-understated__carousel-item-content {
        transition-delay: 0.3s;
    }
}

@keyframes hero-image-pulse {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}

.is-animating {

    .hero-understated__carousel-item-media,
    .hero-understated__carousel-item-content {
        opacity: 1;
        transform: translateX(0);
    }

    .hero-understated__carousel-item-img {
        animation: hero-image-pulse 11s ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
}