/* ======================================== */
/* navigation */
/* ======================================== */

@use 'abstracts' as *;

$dropdown-indicator-size: 2em;

.navigation {
    padding-left: $content-block-pillarbox--xs;
    padding-right: $content-block-pillarbox--xs;
    padding-top: $content-block-letterbox--xs;
    padding-bottom: $content-block-letterbox--xs;

    @include breakpoint(sm) {
        padding-left: $content-block-pillarbox--sm;
        padding-right: $content-block-pillarbox--sm;
        padding-top: $content-block-letterbox--sm;
        padding-bottom: $content-block-letterbox--sm;
    }

    @include breakpoint(md) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        width: 100%;
        margin-bottom: 2em;
        padding: 0;
    }

    @include breakpoint(md-max) {
        position: fixed;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-grow: 1;
        width: 100%;
        height: 100vh;
        overflow-x: scroll;
        background: $white;
        padding-top: 8rem;
        
        &:not(.popout-is-active) {
            // Visually hidden for toggle
            opacity: 0;
            visibility: hidden;
            transition: visibility 0s 0.3s, opacity 0.3s 0s;
        }

        &.popout-is-active {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.3s 0s, transform 0.3s 0s;
        }
    }
}

.site-nav--primary {
    > ul {
        >li {
            > a {
                font-family: $font-stack-alt;
            }
        }
    }
}

.site-nav--secondary {

    @include breakpoint(md) {
        margin-top: auto;
    }

    > ul {
        >li {
            > a {
                font-weight: bold;
            }
        }
    }
}

.site-nav--utility {
    margin-top: 2rem;
}

.site-nav--search {
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    @include breakpoint(md-max) {
        display: none;
    }
}


.site-nav {

    // Level 0
    > ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        line-height: 100%;
        margin: 0;
        padding: 0;

        .dropdown-toggle {
            cursor: pointer;

            @include breakpoint(md) {
                // Devices that support touch
                // @media(hover: none) and (pointer: coarse) {
                    transform: rotate(-90deg);
                    margin-left: 0.5em;
                // }
            }

            &.is-active {
                @include breakpoint(md) {
                    // Devices that support touch
                    // @media(hover: none) and (pointer: coarse) {
                        transform: rotate(90deg);
                    // }
                }
            }
        }
        
        > li {
            position: relative;
            z-index: 2;

            @include breakpoint(md) {
                display: flex;
                justify-content: space-between;
                align-items: center;

                // Devices that support touch
                // @media(hover: none) and (pointer: coarse) {
                    padding-left: 2em;
                    padding-right: 2em;
                // }

                &:after {
                    position: absolute;
                    right: 0;
                    content: "";
                    width: 0;
                    height: 0;
                    border-width: 1rem;
                    border-color: transparent $white transparent transparent;
                    border-style: solid;
                    z-index: 1;
                    transform: translateX(50%);
                    transition: transform 0.3s 0s ease-in-out;

                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s 0.3s, transform 0s 0.3s, opacity 0.3s 0s;
                }
            }

            &.has-children {

                &.selected-root-ancestor {
                    @include breakpoint(md) {
                        // Devices that support touch
                        // @media(hover: none) and (pointer: coarse) {

                            &:after {
                                border-color: transparent rgba(0, 0, 0, 0.05) transparent transparent;
                                transform: translateX(0);
                                opacity: 1;
                                visibility: visible;
                                transition: visibility 0s 0.3s, transform 0.3s 0.3s, opacity 0s 0s;
                            }
                            &.is-active {
            
                                &:after {
                                    border-color: transparent $white transparent transparent;
                                }
                            }
                        // }
                    }
                }
                &.is-active {
                    @include breakpoint(md) {
                        // Devices that support touch
                        // @media(hover: none) and (pointer: coarse) {

                            &:after {
                                transform: translateX(0);
                                opacity: 1;
                                visibility: visible;
                                transition: visibility 0s 0.3s, transform 0.3s 0.3s, opacity 0s 0s;
                            }
                        // }
                    }
                }


                &.selected-root-ancestor {
                    @include breakpoint(md) {
                        // Devices that support hover
                    //     @media(hover: hover) and (pointer: fine) {
                    //         &:after {
                    //             border-color: transparent rgba(0, 0, 0, 0.05) transparent transparent;
                    //             transform: translateX(0);
                    //             opacity: 1;
                    //             visibility: visible;
                    //             transition: visibility 0s 0.3s, transform 0.3s 0.3s, opacity 0s 0s;
                    //         }
                    //         &:hover,
                    //         &:focus {
                    //             &:after {
                    //                 border-color: transparent $white transparent transparent;
                    //             }
                    //         }
                    //     }
                    }
                }
                &:hover,
                &:focus {
                    @include breakpoint(md) {
                        // Devices that support hover
                        // @media(hover: hover) and (pointer: fine) {

                        //     &:after {
                        //         transform: translateX(0);
                        //         opacity: 1;
                        //         visibility: visible;
                        //         transition: visibility 0s 0s, transform 0s 0s, opacity 0s 0s;
                        //     }
                        // }
                    }
                }
            }

            // -------------------------------------------------------------
            // Nav item animations
            // Animation for elements on larger screens when nav is revealed
            // -------------------------------------------------------------

            $animation-delay: 0.1s;
            $transition-speed: 0.5s;
            $n: 20;

            
            &.has-children {

                @include breakpoint(md) {
                    @for $i from 1 through $n {
                        // Nested li
                        > ul > li:nth-child(#{$i}n) > a,
                        > ul > li:nth-child(#{$i}n) > ul {
                            transform: translateY(1em);
                            opacity: 0;
                            visibility: hidden;
                            transition: visibility 0s 0s, opacity 0s 0s, transform 0s 0s;
                        }
                    }
                }

                &.is-active {
                    @include breakpoint(md) {
                        // Devices that support touch
                        // @media(hover: none) and (pointer: coarse) {

                            @for $i from 1 through $n {
                
                                > ul > li:nth-child(#{$i}n) > a,
                                > ul > li:nth-child(#{$i}n) > ul {
                                    transform: translateY(0);
                                    opacity: 1;
                                    visibility: visible;
                                    transition: opacity $transition-speed #{$i * $animation-delay}, transform $transition-speed #{$i * $animation-delay};
                                }
                            }
                        // }
                    }
                }

                &:hover,
                &:focus {
                    @include breakpoint(md) {
                        // Devices that support hover
                        // @media(hover: hover) and (pointer: fine) {

                        //     @for $i from 1 through $n {
                
                        //         > ul > li:nth-child(#{$i}n) > a,
                        //         > ul > li:nth-child(#{$i}n) > ul {
                        //             transform: translateY(0);
                        //             opacity: 1;
                        //             visibility: visible;
                        //             transition: opacity $transition-speed #{$i * $animation-delay}, transform $transition-speed #{$i * $animation-delay};
                        //         }
                        //     }

                        // }
                    }
                }
            }
            // End nav animations

            > a {
                @include breakpoint(md) {
                    font-size: 1.125rem;
                    // Devices that support touch
                    // @media(hover: none) and (pointer: coarse) {
                        justify-content: flex-start;
                        padding: 0.75em 0;
                    // }

                    // Devices that support hover
                    // @media(hover: hover) and (pointer: fine) {
                    //     flex-grow: 1;
                    //     display: flex;
                    //     justify-content: center;
                    //     align-items: baseline;
                    //     padding: 0.5em 2em;
                    // }
                }

                .dropdown-indicator {
                    @include breakpoint(md) {
                        // Devices that support hover
                        // @media(hover: hover) and (pointer: fine) {
                        //     transform: translateY(-25%) rotate(-45deg);
                        //     transform-origin: center center;
                        // }
                    }
                }
            }
            
            &:hover {

                // Level 2
                > ul {
                    @include breakpoint(md) {
                        // Devices that support hover
                        // @media(hover: hover) and (pointer: fine) {
                        //     transform: translateY(0) translateX(0%);
                        //     opacity: 1;
                        //     visibility: visible;
                        //     transition: opacity 0.3s 0s, transform 0.3s 0s;
                        // }
                    }
                }
            }
        }
    }

    // Level 1
    > ul > li > ul {
        z-index: 1;
        width: 100%;
        display: block;

        // Visually hidden for toggle
        opacity: 0.5;
        visibility: hidden;
        transition: visibility 0s 0.3s, opacity 0.3s 0s;

        @include breakpoint(md) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // align-content: safe center; // Not working in safari
            align-content: flex-start;
            position: fixed;
            right: 0;
            top: 0;
            width: calc(100vw - #{$sidebar-width});
            height: 100vh;
            background: rgba($white, 0.95);
            box-shadow: 0 0px 100px 10px rgb(0 0 0 / 10%);
            overflow-y: scroll;
            padding: $content-block-letterbox--md $content-block-pillarbox--md;
            column-gap: 3rem;
        }

        @include breakpoint(lg) {
            padding:  $content-block-letterbox--lg $content-block-pillarbox--lg;
        }

        @include breakpoint(mx) {
            padding:  $content-block-letterbox--mx $content-block-pillarbox--mx;
        }

        &.is-active {
            @include breakpoint(md-max) {
                opacity: 1;
                visibility: visible;
                transition: opacity 0.3s 0s, transform 0.3s 0s;
            }

            @include breakpoint(md) {
                // @media(hover: none) and (pointer: coarse) {
                    opacity: 1;
                    visibility: visible;
                    transition: opacity 0.3s 0s, transform 0.3s 0s;
                // }
            }
        }

        .dropdown-toggle {
            @include breakpoint(md) {
                display: none; // Hide second + level toggle as not needed to see next level
            }
        }

        > li {
            @include breakpoint(md) {
                flex-basis: calc(50% - 3rem); // Default to half width for simple list item with no child list
                flex-grow: 0;
                display: flex;
                padding: 3rem 0;
                border-bottom: 1px solid rgb(var(--t-highlight));
            }

            &.has-children {
                @include breakpoint(md) {
                    align-items: flex-start;
                    flex-basis: 100%; // Allow more space for list item and child list
                    column-gap: 3rem;

                    a {
                        position: sticky;
                        top: 0;
                    }
                }
            }

            > a,
            > ul {
                @include breakpoint(md) {
                    flex: 1 0 calc(50% - 3rem);
                }
            }

            > a {

                &.show-nav-default-link {
                    @include breakpoint(md) {
                        font-size: 2em;
                        padding-right: 2em;
                        font-family: $font-stack-alt;
                    }
                }

                &.hide-nav-default-link {
                    @include breakpoint(md) {
                        display: none;
                    }
                }

                &.signpost {
                    @include breakpoint(md-max) {
                        display: none;
                    }
                }
            }
        }
    }

    // Level 2
    > ul > li > ul > li > ul {

        @include breakpoint(md) {

            flex-basis: 50%;
            flex-grow: 1;
            flex-shrink: 0;

            &.is-active {
                // Devices that support touch
                // @media(hover: none) and (pointer: coarse) {
                //     transform: translateY(0) translateX(0%);
                //     opacity: 1;
                //     visibility: visible;
                //     transition: opacity 0.3s 0s, transform 0.3s 0s;
                // }
            }

            li {
                margin-bottom: 1em;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    justify-content: flex-start;
                    width: auto;
                    text-align: left;
                }
            }
        }
    }
    
    // General
    ul {
        list-style-type: none;

        @include breakpoint(md-max) {
            width: 100%;
        }

        li {
            @include breakpoint(md-max) {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                flex-wrap: wrap;
                min-height: 2.5em;
            }

            ul {
                @include breakpoint(md-max) {
                    border-radius: 0.5rem;
                    display: none; // Hide 2nd level and down and controlled via js
                    background: rgba($light-blue, 0.1);
                    margin: 0.5rem 0;
                    padding: 1rem 0 1rem 1rem;
                }

                &.is-active {
                    @include breakpoint(md-max) {
                        display: block;
                    }
                }

                ul {
                    @include breakpoint(md-max) {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }

                li {
                    .dropdown-toggle {
                        @include breakpoint(md-max) {
                            margin-right: 1em;
                        }
                    }
                }

                a {
                    @include breakpoint(md-max) {
                        font-size: 1em;
                        flex-grow: 0;
                        max-width: calc(100% - 4em);
                        margin: 0.5em 0;
                    }
                }
            }
        }
    }


    li {
        margin: 0;

        a {
            position: relative;
            line-height: 1.125;
            color: rgb(var(--t-content));

            @include breakpoint(md-max) {
                font-size: 1.125em;
            }

            &:hover,
            &:focus {
                color: $core-blue;
                
                .icon-nav-link__icon {
                    > svg {
                        .theme-icon-bg {
                            fill: rgb(var(--t-linkbutton-icon-bg--action));
                        }
                        .theme-icon-content-stroke {
                            stroke: rgb(var(--t-linkbutton-icon-content--action));
                        }
                        .theme-icon-content-fill {
                            fill: rgb(var(--t-linkbutton-icon-content--action));
                        }
                    }
                }
            }

            > span {
                position: relative;
            }

            .icon-nav-link {
                display: flex;
                align-items: center;

                &__icon {
                    display: none;

                    @include breakpoint(md) {
                        display: block;
                        width: 2em;
                        margin-right: 0.5em;
                        
                        > svg {
                            width: 100%;

                            .theme-icon-bg {
                                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                                transition: fill 0.3s 0s ease-in-out;
                            }
                            .theme-icon-content-stroke {
                                stroke: rgb(var(--t-linkbutton-icon-content));
                                transition: stroke 0.3s 0s ease-in-out;
                            }
                            .theme-icon-content-fill {
                                fill: rgb(var(--t-linkbutton-icon-content));
                                transition: fill 0.3s 0s ease-in-out;
                            }
                        }
                    }
                }
            }
        }

        &.selected > a {
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-decoration-color: currentColor;
        }        
    }
}


.dropdown-indicator {
    @include breakpoint(md-max) {
        display: none;
    }
    
    @include breakpoint(md) {
        display: block;
        margin: 0 0 0 0.5em;
        padding: 0;
        width: 0.5em;
        height: 0.5em;
        border-style: solid;
        border-width: 0 1px 1px 0;
        border-color: currentColor;

        // Devices that don't support hover but support touch
        // @media(hover: none) and (pointer: coarse) {
        //     display: none;
        // }
    }
}

.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $dropdown-indicator-size;
    height: $dropdown-indicator-size;
    border: none;
    border-radius: 1.5em;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    transition: transform 0.2s 0s ease-out;
    background: rgba($light-blue, 0.2); // Always lightly darker than background

    @include breakpoint(md) {
        // Devices that support hover
        // @media(hover: hover) and (pointer: fine) {
        //     display: none;
        // }
    }

    &__chevron {
        display: block;
        margin: 0;
        padding: 0;
        width: 0.5em;
        height: 0.5em;
        border: 1px solid;
        border-color: transparent currentColor currentColor transparent;
        transform: translateY(-25%) rotate(45deg);
        transform-origin: center center;
    }

    &:hover,
    &:focus,
    &.is-active {
        background: rgb(var(--t-linkbutton-icon-bg--action));
        .dropdown-toggle__chevron {
            border-color: transparent rgb(var(--t-linkbutton-icon-content--action)) rgb(var(--t-linkbutton-icon-content--action)) transparent;
        }
    }

    &.is-active {
        transform: rotate(180deg);
        transition-delay: 0s;

        &:focus {
            background: rgb(var(--t-linkbutton-icon-bg--action));
        }
    }
}

// disable body scroll 
.scroll-disabled {
    @include breakpoint(md) {
        overflow-y: hidden;
    }
}