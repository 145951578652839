// ----------------------------------------
// site color variables
// add custom site color variables here
// implement in theme.scss
// ----------------------------------------

@use 'sass:color';

// Assign your custom colours as root variables

// shades
$white: #ffffff;
$black: #191919;

// brand colors
$red: #D43A59;
$green: #94CE36;
$yellow: #F8B133;
$light-blue: #ADD8F2;
$core-blue: #004E8A;

$off-white: color.scale($light-blue, $lightness: 75%);



// shades
$white: #ffffff;
$white_rgb: 255, 255, 255;

$black: #191919;
$black_rgb: 25, 25, 25;


// brand colors
$red: #D43A59;
$red_rgb: 212, 58, 89;

$green: #94CE36;
$green_rgb: 148, 206, 54;

$yellow: #F8B133;
$yellow_rgb: 248, 177, 51;

$light-blue: #ADD8F2;
$light-blue_rgb: 173, 216, 242;

$core-blue: #004E8A;
$core-blue_rgb: 0, 78, 138;

$off-white: color.scale($light-blue, $lightness: 75%);
$off-white_rgb: 
    color.red(color.scale($light-blue, $lightness: 75%)), 
    color.green(color.scale($light-blue, $lightness: 75%)), 
    color.blue(color.scale($light-blue, $lightness: 75%));



// @use 'sass:color';

// $green: #94CE36;
// $green_rgb: 148, 206, 54;

// $red: #D43A59;
// $red_rgb: 212, 58, 89;

// $light-green: color.scale($green, $lightness: 75%);
// $light-green_rgb: 
//   color.red($light-green), 
//   color.green($light-green), 
//   color.blue($light-green);

// :root {
//   --t-content-1: #{$green_rgb};
//   --t-content-2: #{$red_rgb};
// }

// .theme-1 {
//   --t-content-1: #{$light-green_rgb};
  
//   // Option 1
//   $temp: color.scale($light-green, $saturation: 50%);
//   $temp_rgb: color.red($temp), color.green($temp), color.blue($temp);
  
//   // Option 2
//   $temp_rgb: 
//     color.red(color.scale($light-green, $saturation: 50%)), 
//     color.green(color.scale($light-green, $saturation: 50%)), 
//     color.blue(color.scale($light-green, $saturation: 50%));
  
//   // OUTPUT
//   --t-content-2: #{$temp_rgb};
// }


// h1 {
//   color: rgba(var(--t-content-1), 0.5);
// }

// p {
//   color: rgba(var(--t-content-2), 0.5);
// }