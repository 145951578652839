/* ======================================== */
/* button */
// <button> element
/* ======================================== */

@use 'abstracts' as *;

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {

}

.button-icon {
    background: transparent; // Reset
    border: none; // Reset
    display: flex;
    align-items: center;
    gap: 0.5rem;
        
    svg {
        width: 100%;

        .theme-icon-bg {
            fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
            transition: fill 0.3s 0s ease-out;
        }
        .theme-icon-content-fill {
            fill: rgb(var(--t-linkbutton-icon-content));
            transition: fill 0.3s 0s ease-out;
        }
        .theme-icon-content-stroke {
            stroke: rgb(var(--t-linkbutton-icon-content));
            transition: stroke 0.3s 0s ease-out;
        }
    }

    &:hover,
    &:focus {

        .button-icon__text {
            color: rgb(var(--t-linkbutton--action));
        }

        svg {

            .theme-icon-bg {
                fill: rgb(var(--t-linkbutton-icon-bg--action));
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content--action));
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content--action));
            }
        }
    }

    &__text {
        font-family: $font-stack-alt;
        color: rgb(var(--t-linkbutton));
        font-weight: normal;
        transition: color 0.3s 0s ease-out;
    }

    &__icon {
        width: 2rem;
    }
}

//-------------------------------------------------
// Submit icon (used to replace submit input button

.submit-icon {
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    svg {

        .theme-icon-bg {
            fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
            transition: fill 0.1s 0s ease-out;
        }
        .theme-icon-content-fill {
            fill: rgb(var(--t-linkbutton-icon-content));
            transition: fill 0.1s 0s ease-out;
        }
        .theme-icon-content-stroke {
            stroke: rgb(var(--t-linkbutton-icon-content));
            transition: stroke 0.1s 0s ease-out;
        }
    }

    &:hover,
    &:focus {

        svg {
                    
            .theme-icon-bg {
                fill: rgb(var(--t-linkbutton-icon-bg--action));
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content--action));
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content--action));
            }
        }
    }

    input[type="submit"] {
        display: none;
    }
}

.input-inner-icon {
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}