/* ======================================== */
/* article card */
/* ======================================== */

@use "abstracts" as *;
@use 'sass:color';

.article-card {
    display: block;
    position: relative;
    text-decoration: none;
    background-color: rgb(var(--t-bg--action));
    color: rgb(var(--t-content));
    transition-property: background-color, color;
    transition-duration: 0.3s;
    transition-delay: 0s;
    transition-timing-function: ease-in-out;

    &:hover,
    &:focus {
        // color: rgb(var(--t-content));
        background-color: rgb(var(--t-standout-bg));
        color: rgb(var(--t-standout-content));

        .article-card {

            &__image {
                transform: scale(1.1);
            }

            &__media {

                &:before {
                    height: 0.5rem;
                }
            }

            &__cta {
                    
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }

        .tag-list__tag {
            background-color: rgb(var(--t-standout-bg--action));
        }

        .logo-placeholder-theme-bg {
            fill: rgb(var(--t-standout-content));
        }
    }

    &__media {
        width: 100%;
        margin: 0;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 0;
            z-index: 1;
            background: rgb(var(--t-highlight));
            transition-property: height;
            transition-duration: 0.3s;
            transition-delay: 0s;
            transition-timing-function: ease-in-out;
        }
    }

    &__picture {
        width: 100%;
        overflow: hidden;
        display: block;
    }

    &__image {
        width: 100%;
        transition: transform 0.3s 0s ease-in-out;
        display: block;
    }
    
    &__content {
        position: relative;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        > *:first-child { margin-top: 0;}
        > *:last-child { margin-bottom: 0;}
    }

    &__type {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        font-size: 0.5rem;
        padding: 0.625em 1em 0.5em 1em;
        font-family: $font-stack-alt;
        line-height: 1;
        background: rgb(var(--t-highlight-bg));
        color: rgb(var(--t-highlight-content));
        border-radius: 0 0 0 0.25em;
        text-transform: uppercase;
        letter-spacing: 0.125em;
        transition: all 0.3s 0s ease-in-out;
    }

    &__tags {
        margin-top: auto;
        padding-bottom: 1rem;
    }

    .tag-list__tag {
        background: rgb(var(--t-bg));
    }

    &__heading {
        font-weight: bold;
    }

    &__summary {
        margin-bottom: 1rem;
    }

    &__location {
        margin: 0;
    }

    &__meta {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: auto 0 1rem 0;

        p {margin: 0;}
    }

    &__date {
        flex-shrink: 0;
        flex-grow: 0;

        &-day {
            font-size: 2rem;
            line-height: 1;
            font-weight: bold;
            display: block;
        }
        
        &-month-year {
            font-size: 0.75rem;
            line-height: 1;
            text-transform: uppercase;
            display: block;
        }

        &-divider {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            font-size: 2rem;
        }
    }

    &__cta {
        flex-grow: 0;
        width: 2rem;
        margin-left: auto;

        > img,
        > svg {
            width: 100%;
            height: auto;
        }

        .theme-icon-bg {
            fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
            transition: fill 0.3s 0s ease-out;
        }
        .theme-icon-content-fill {
            fill: rgb(var(--t-linkbutton-icon-content));
            transition: fill 0.3s 0s ease-out;
        }
        .theme-icon-content-stroke {
            stroke: rgb(var(--t-linkbutton-icon-content));
            transition: stroke 0.3s 0s ease-out;
        }
    }
}
