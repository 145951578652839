/* ======================================== */
/* Signpost */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

$signpost-transition-duration: 0.3s;
$signpost-gutter: 2rem;

.signpost-reveal {

    &__heading {
        font-size: 1.75rem;
        margin-bottom: 0;
        line-height: 1;

        @include breakpoint(sm) {
            font-size: 2rem;
        }

        @include breakpoint(mx) {
            font-size: 2.5rem;
        }
    }

    &__target {
        z-index: 100;
        position: fixed;
        display: flex;
        align-items: flex-start;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(var(--t-bg), 0.95);
        color: rgb(var(--t-content));
        overflow: auto;

        // Visually hidden for toggle
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s 0.3s, opacity 0.3s 0s;

        @include breakpoint(md) {
            width: calc(100vw - #{$sidebar-width});
        }

        &.popout-is-active {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.3s 0s, transform 0.3s 0s;
        }

        &-inner {
            position: relative;
            z-index: 1;
            margin: auto;
            display: block;
            width: 100%;
            @extend %content-vertical-spacing;
            @extend %content-horizontal-spacing;
            max-width: $max-content-width;
        }
    }

    &__close {
        position: fixed;
        right: 2rem;
        top: 2rem;
        z-index: 2;
        width: 2rem;
        border: none;
        background-color: transparent;
        cursor: pointer;
        
        svg {
            width: 100%;
    
            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-out;
            }
        }

        &:hover,
        &:focus {

            svg {
    
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }
    }

    &__btn {
        $btn-size: 1.25rem;
        $btn-position: math.div($btn-size, 2);

        display: inline-block;
        background: transparent;
        border-top: none;
        border-right: none;
        border-bottom: solid 2px rgb(var(--t-content));
        border-left: none;
        color: rgb(var(--t-content), 0.5);
        font-size: #{$btn-size * 2};
        padding: 0.25rem 0;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover, &:focus {
            color: rgb(var(--t-content), 1);
        }

        &::after {
            content: "";
            display: inline-block;
            height: 1rem;
            width: 1rem;
            border-right: 2px solid;
            border-bottom: 2px solid;
            border-color: rgb(var(--t-content));
            transform: rotate(45deg) translateY(-$btn-position);
            margin: 0 1rem;
        }
    }
}

.signpost-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: $signpost-gutter;
    row-gap: $signpost-gutter;
    margin: 4rem 0;

    @include breakpoint(sm) {
        row-gap: $signpost-gutter*2;
    }
}

.signpost {
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    .signpost-reveal & {
        // Waypoints don't play well with scrollable area;
        opacity: 1;
        transform: none;
    }

    @include breakpoint(xs-max) {
        flex-direction: row-reverse;
    }

    .signpost-list & {
        flex-basis: 100%;
        flex-grow: 0;

        @include breakpoint(lg) {
            flex-basis: calc(50% - (#{$signpost-gutter}));
            max-width: calc(50% - (#{$signpost-gutter}/2));
        }
    }

    &:hover,
    &:focus {
        .signpost__media {
            box-shadow: 0.25rem 0.25rem 2rem 0 rgba(0, 0, 0, 0.35);
            transform: scale(1.1);

            &:before {
                width: 0.5rem;
            }
        }

        .signpost__img {
            transform: scale(1.1);
        }

        .signpost__content {
            color: rgb(var(--t-link--neutral));
        }

        .signpost__icon {
            > svg {
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }
    }

    &__media {
        flex-basis: 100px;
        flex-grow: 0;
        flex-shrink: 0;
        box-shadow: 0.25rem 0.25rem 1rem 0 rgba(0, 0, 0, 0.25);
        transition-property: box-shadow transform;
        transition-duration: $signpost-transition-duration;
        transition-delay: 0s;
        transition-timing-function: ease-out;

        @include breakpoint(sm) {
            flex-basis: 150px;
        }

        &:before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            right: 100%;
            width: 0;
            height: 100%;
            transition: width $signpost-transition-duration 0s ease-out;
            background-color: rgb(var(--t-highlight));
        }
    }

    &__picture {
        width: 100%;
        display: block;
        overflow: hidden;
    }

    &__img {
        width: 100%;
        display: block;
        transition: transform $signpost-transition-duration 0s ease-out;
    }

    &__content {
        flex-grow: 1;
        transition: all $signpost-transition-duration 0s ease-out;
        color: rgb(var(--t-link--neutral));
        max-width: 45ch;
    }

    &__icon {
        width: 2rem;
        display: block;

        > img,
        > svg {
            width: 100%;
        }

        > svg {
            .theme-icon-bg {
                fill: rgb(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-in-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-in-out;
            }
        }
    }
}


// Compact view
.signpost-list--compact {
    width: 100%;
    display: block;
    margin: 4rem 0;
}

.signpost-compact {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(var(--t-bg--action));
    padding: 0.5em 0;

    .signpost-reveal & {
        // Waypoints don't play well with scrollable area;
        opacity: 1;
        transform: none;
    }

    &:first-child {
        border-top: 1px solid rgb(var(--t-bg--action));
    }

    &:hover,
    &:focus {

        .signpost-compact__content {
            color: rgb(var(--t-link--neutral));
        }

        .signpost-compact__icon {
            > svg {
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }
    }

    &__content {
        flex-grow: 1;
        transition: all $signpost-transition-duration 0s ease-out;
        color: rgb(var(--t-link--neutral));
    }

    &__heading {
        margin: 0;
    }

    &__icon {
        flex-basis: 2rem;
        flex-shrink: 0;
        flex-grow: 0;
        display: block;
        margin-left: 1rem;

        > img,
        > svg {
            width: 100%;
        }

        > svg {
            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-in-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-in-out;
            }
        }
    }
}

// Detaild - No Image view

.signpost-list--detailed-no-image {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    row-gap: 2rem;
    align-items: stretch;

    .layout--fullwidth & {

    }
}

.layout--fullwidth {
    .signpost-list--detailed-no-image {
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    }
}

.signpost-card {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: rgb(var(--t-bg--action));
    color: rgb(var(--t-content));
    border: solid 1px rgba(var(--t-content), 0.1);
    transition: all $signpost-transition-duration 0s ease-out;
    

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: -1px;
        left: -1px;
        width: 0;
        height: calc(100% + 2px);
        transition: width $signpost-transition-duration 0s ease-out;
        background-color: rgb(var(--t-highlight));
    }

    &:hover,
    &:focus {
        color: rgb(var(--t-content));
        box-shadow: 0.25rem 0.25rem 1rem 0 rgb(0, 0, 0, 25%);
        border-color: none;
        transform: translateY(-3px);

        &:before {
            width: 0.5rem;
        }

        .signpost-card__icon {
            > svg {
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }
    }

    &__title {
        font-size: 0.75rem;
        text-align: right;
        margin-bottom: 2.5rem;
    }

    &__heading {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1.25rem;
    }

    &__summary {

    }

    &__icon {
        display: block;
        width: 2rem;
        align-self: flex-end;
        margin-top: auto;

        > img,
        > svg {
            width: 100%;
        }

        > svg {
            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-in-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-in-out;
            }
        }
    }
}