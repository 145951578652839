/* ======================================== */
/* stats */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

$stats-gutter: 4rem;

.stats {
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 4rem 0;
    row-gap: math.div($stats-gutter, 2);
    column-gap: $stats-gutter;

    &__item {
        position: relative;
        max-width: 100%;
        flex-grow: 1;
        margin: 0;

        @include breakpoint(sm) {
            flex-basis: calc(50% - (#{$stats-gutter}));
        }

        @include breakpoint(md) {
            flex-basis: calc(33.333333% - (#{$stats-gutter}));
        }

        @include breakpoint(lg) {
            flex-basis: calc(25% - (#{$stats-gutter}));
        }
    }

    &__figure {
        font-size: 2rem;
        margin-bottom: 0.125em;
        font-family: $font-stack-alt;
        color: rgb(var(--t-content--action));
        line-height: 1;

        @include breakpoint(sm) {
            font-size: 3rem;
        }

        @include breakpoint(mx) {
            font-size: 3vw;
        }
    }

    &__summary {
        font-weight: bold;
        max-width: 30ch;
        color: rgb(var(--t-content--action));

        > *:last-child {
            margin-bottom: 0;
        }

        p {
            line-height: 1.125;
        }
    }
}