/* ======================================== */
/* cta-strip */
/* ======================================== */

@use 'abstracts' as *;

.cta-strip {
    display: flex;
    width: 100%;
    align-items: stretch;
    background-color: rgb(var(--t-standout-bg));
    color: rgb(var(--t-standout-linkbutton));
    margin: 4rem 0;

    &:hover,
    &:focus {
        background-color: rgb(var(--t-standout-bg));
        color: rgb(var(--t-standout-linkbutton));
                    
        .theme-icon-bg {
            fill: rgb(var(--t-standout-linkbutton-icon-bg--action));
        }
        .theme-icon-content-fill {
            fill: rgb(var(--t-standout-linkbutton-icon-content--action));
        }
        .theme-icon-content-stroke {
            fill: rgb(var(--t-standout-linkbutton-icon-content--action));
        }
    }

    &__content-wrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 2rem 2.5rem 2rem;
        border-left: 1rem solid rgb(var(--t-highlight));

        @include breakpoint(sm) {
            border-width: 2rem;
        }
    }

    &__content {
        width: 100%;
        display: block;
    }

    &__heading {
        margin-bottom: 0.25em;
        line-height: 1.25;
    }

    &__body {
        margin: 0;
        line-height: 1.25;
    }

    &__icon {
        width: 3rem;
        margin-left: 2rem;

        > svg,
        > img {
            width: 100%;
        }

        .theme-icon-bg {
            fill: rgba(var(--t-standout-linkbutton-icon-bg), 0.05);
            transition: fill 0.3s 0s ease-out;
        }
        .theme-icon-content-fill {
            fill: rgb(var(--t-standout-linkbutton-icon-content));
            transition: fill 0.3s 0s ease-out;
        }
        .theme-icon-content-stroke {
            fill: rgb(var(--t-standout-linkbutton-icon-content));
            transition: stroke 0.3s 0s ease-out;
        }

    }

    &__media {
        display: none;
        flex-grow: 0;
        flex-shrink: 0;

        @include breakpoint(sm) {
            display: block;
        }
    }

    &__picture {

    }

    &__image {
        height: 200px;
        width: auto;
        display: block;
    }
}