/* ======================================== */
/* content prominent */
/* ======================================== */

.content-prominent {
    font-size: 1.25rem;
    line-height: 1.25;

    > p {
        font-size: 1.25rem;
        line-height: 1.25;
    }
}