/* ======================================== */
/* Page Layout */
/* ======================================== */

@use '../abstracts' as *;

body {
    min-height: 100vh; 
}

/* ----- Site Grid ----- */

.site-layout {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    grid-template-areas: "header header header"
                         "main main main"
                         "footer footer footer"
    ;
}

.site-layout-side-nav {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 90px 1fr;
    grid-template-areas: "header header header"
                         "main main main"
                         "footer footer footer"
    ;

    @include breakpoint(sm) {
        grid-template-rows: 135px 1fr;
    }

    @include breakpoint(md) {
        grid-template-columns: $sidebar-width 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
                            "header main main"
                            "header footer footer"
        ;
    }
}

// Grid elements
header {
    grid-area: header;
    position: relative;
    z-index: 3;
}

main {
    grid-area: main;
    position: relative;
    z-index: 2;
}

footer {
    grid-area: footer;
    position: relative;
    z-index: 1;
}

/* ----- Content Width ----- */

// Width limit for main content
.container {
    display: block;
    width: 100%;
    
    margin-left: auto;
    margin-right: auto;

    > .layout:last-of-type { // Here we are just ensuring that any layouts INSIDE OF .container don't end up adding to vertical margins
        margin-bottom: 0;
    }
}
