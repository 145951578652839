/* ======================================== */
/* header */
/* ======================================== */

@use '../abstracts' as *;

$hamburger-size: 20px; // make it the same size as the navbar
$hamburger-line-width: 60%; // Sizing calculated from navbar height
$hamburger-line-height: 2px; // Sizing calculated from navbar height

.side-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;
    box-shadow: 0 0px 100px 10px rgb(0 0 0 / 10%);

    @include breakpoint(md-max) {
        // Fixed navigation on mobile
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        transition: top 0.5s ease-in-out;

        &.is-hidden {
            top:-135px;
        }
    }

    @include breakpoint(md) {
        position: sticky;
        top: 0;
        height: 100vh;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    &__toolbar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem $content-block-pillarbox--xs;

        @include breakpoint(sm) {
            padding: 2rem $content-block-pillarbox--sm;
        }

        @include breakpoint(md) {
            justify-content: center;
            padding: 0 2rem 4rem 2rem;
        }
    }
}

.header-image-mobile {

    > svg,
    > img {
        height: 3rem;

        @include breakpoint(sm) {
            height: 4rem;
        }
    }

    @include breakpoint(md) {
        display: none;
    }
}

.header-image {
    display: none;
    
    @include breakpoint(md) {
        display: block;
    }

    > svg,
    > img {
        width: 100%;
        max-height: 100px;
    }
}

.navigation-trigger {
    font-family: $font-stack;
    padding: 1em 0;
    border-radius: 0.25em;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin-left: 1rem;
    border: none;
    background: transparent;
    color: rgb(var(--t-link--neutral));
    transition: all 0.3s 0s;

    @include breakpoint(md) {
        display: none;
    }

    &__text {
        font-family: $font-stack-alt;
        text-transform: uppercase;
        // letter-spacing: 0.125em;
        font-size: 0.75rem;
    }

    &__icon {
    }

    &.popout-is-active {
        z-index: 40;
        background: rgb(var(--t-bg));

        &:hover,
        &:focus {
            .hamburger {
                background-color: transparent;
            }
        }
    }

    &:hover,
    &:focus {
        color: rgb(var(--t-link));
        .hamburger {
            background-color: rgb(var(--t-link));
    
            &:before,
            &:after {
                background: rgb(var(--t-link));
            }
        }
    }
}

.hamburger {
    position: relative;
    display: block;
    transition-property: background, top;
    transition-duration: 0.2s;
    transform: translateZ(0);
    width: $hamburger-size;
    height: $hamburger-line-height;
    background-color: rgb(var(--t-link--neutral));
    border-radius: $hamburger-line-height;

    &:focus {
        outline: 0;
    }

    //--------------------------------------

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateZ(0);
        border-radius: $hamburger-line-height;
        left: 0;
        background-color: rgb(var(--t-link--neutral));
        transform-origin: center center;
        transition-property: background, transform, top, bottom, opacity;
        transition-duration: 0.2s;
    }

    &:before { 
        bottom: 300%; 
    }

    &:after { top: 300%;}

    //--------------------------------------

    /* When the navigation is opened */
    .navigation-trigger.popout-is-active & {
        background-color: transparent;

        &:before {
            bottom: 0;
            transform: rotate(-45deg);
        }

        &:after {
            top: 0;
            transform: rotate(45deg);

        }
    }
}