/* ======================================== */
/* result-filter */
/* ======================================== */

@use 'abstracts' as *;

.result-filter {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction:column;
    justify-content: center;
    align-items:center;

    @include breakpoint(sm-max) {
        display:block;
    }

    &__intro {
        margin-bottom: 1em;
        width: 100%;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__group {
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: center;
        gap: 0.5rem;
        padding: 2rem;
        background: rgb(var(--t-bg--action));

        @include breakpoint(md-max) {
            flex-wrap: wrap;
        }
    }

    &__item {
        display: flex;
        justify-content: center;

        @include breakpoint(sm-max) {
            width: 100%;
        }

        @include breakpoint(md) {
            flex-grow: 1;
            flex-basis: auto;
        }
    }

    &__submit {
        width: 2rem;
        flex-shrink: 0;
        flex-grow: 0;

        @include breakpoint(sm-max) {
            margin-left: auto;
        }
    }

    &__item-content {
        flex-direction: column;

        @include breakpoint(md) {
            margin: 0;
        }
    }

    &__label {
        display: block;
        margin-bottom: 0.5em;
        font-weight: bold;
    }
}