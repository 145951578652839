/* ======================================== */
/* block image text */
/* ======================================== */

@use "abstracts" as *;

.image-text {
    display: grid;
    grid-template-areas: "media"
                         "content";

    @include breakpoint(md) {
        grid-template-areas: "content media";
        grid-template-columns: repeat(2, minmax(0, 1fr));

        &--half {
            // Default state
        }

        &--wide {
            grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
        }

        &--short {
            grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
        }

        &--reverse {
            grid-template-areas: "media content";
    
            &.image-text--wide {
                grid-template-columns: 2fr 1fr;
            }
    
            &.image-text--short {
                grid-template-columns: 1fr 2fr;
            }

            .image-text__picture:before {
                left: 100%;
            }
        }
    }

    &__content {
        @extend %content-vertical-spacing;
        @extend %content-horizontal-spacing;

        grid-area: content;
        display: flex;
        flex-direction: column;
        color: var(--t-content);
    }

    &__media {
        grid-area: media;
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100vh;

        @include breakpoint(md) {
            position: sticky;
            top: 0;
        }
    }

    &__picture {
        position: relative;
        display: block;
        height: 100%;

        &:before {
            background-color: rgb(var(--t-highlight));
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 0.5rem;
            z-index: 1;

            @include breakpoint(md) {
                height: 100%;
                left: 0;
                top: 0;
                width: .5rem;
            }
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

// Adjust eyebrow for this block
.image-text {
    @include breakpoint(md) {

        // Sett additional padding top so it starts at the same point as text.
        .eyebrow-heading {
            padding-top: 6vw;
        }
        
        // If columns are reversed and image is first align eyebrow back to top.
        &--reverse {
            .eyebrow-heading {
                padding-top: 0;
            }
        }
    }
}