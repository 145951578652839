/* ======================================== */
/* page_article_single */
/* ======================================== */

@use "abstracts" as *;
@use 'sass:color';

.article-intro {
    @extend %content-horizontal-spacing;
    @extend %content-vertical-spacing;
}

.article-body {
    @extend %content-horizontal-spacing;
    @extend %content-vertical-spacing;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.article-aside {
    @include breakpoint(md) {
        // Move it to side on this break point
        order: 1;

        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}

.article-featured-media {

    &__picture {
        width: 100%;
        display: block;
    }

    &__image {
        width: 100%;
        display: block;
    }
}

// Meta - aside content
.meta-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;

    @include breakpoint(xs) {
        gap: 1.5rem;
        align-items: stretch;
    }

    &__item {

        @include breakpoint(xs) {
            border-right: 1px solid;
            padding-right: 1.5rem;
            display: flex;
            align-items: center;
    
            &:last-child {
                border-right: 0;
                padding-right: 0;
            }
        }

        &--jumpto {
            flex-basis: auto;
            flex-grow: 0;

            @include breakpoint(sm-max) {
                flex-basis: 100%;
                order: 1;
                border-right: none;
                padding-right: 0;
            }
        }
    }

    &__tag {
        width: 100%;
    }

    &__read-time {
    }

}

.meta-author {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    margin-bottom: 2rem;
    background: rgb(var(--t-bg--action));

    &__avatar {
        display: flex;
        align-items: center;
        gap: 1rem;
        color: rgb(var(--t-content--action));
    }

    &__image {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        margin-bottom: auto;
    }

    &__details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__name {
        font-family: $font-stack-alt;
    }

    &__position {

    }

    &__summary {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-end;
        column-gap: 2rem;

        @include breakpoint(sm) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    &__bio {
        flex-grow: 1;
        max-width: 75ch;
    }

    &__social {
        flex-shrink: 0;
        display: flex;

        @include breakpoint(sm) {
            align-items: flex-end;
        }
    }
}

// Previous & Next Article
.latest-news {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include breakpoint(sm) {
        flex-direction: row;
    }

    &__item {
        @include breakpoint(sm) {
            flex-basis: calc(50% - 1rem);
        }
    }

    &__previous {
        
    }

    &__next {
        text-align: right;
    }
}
