/* ======================================== */
/* footer */
/* ======================================== */

@use 'abstracts' as *;

.footer {
    width: 100%;
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
    margin-top: $content-block-letterbox--xs;

    @each $breakpoint, $size in $content-block-letterbox-sizes {
        margin-top: $size;
    }

    @include breakpoint(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__content {

        @include breakpoint(sm) {
            flex-grow: 0;
            max-width: 55ch;
            padding-right: 4rem;
        }
    }

    &__aside {
        flex-shrink: 0;
    }

    &__links {

        @include breakpoint(sm) {
            flex-shrink: 0;
        }
    }

    ul {
        padding: 0;

        li {
            list-style: none;
            margin: 0;
        }
    }
}



.footer-utility {
    font-size: 0.75rem;

    p {
        font-size: 0.75rem;
    }

    a {
        color: rgb(var(--t-link--neutral));

        &:focus,
        &:hover {
            color: rgb(var(--t-link));
        }
    }
}

.utility {
    width: 100%;

    @include breakpoint(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__nav {
        ul {
            padding: 0;

            @include breakpoint(sm) {
                display: flex;
                flex-wrap: wrap;
            }

            li {
                list-style: none;
                padding-right: 1rem;

                a {
                    font-family: $font-stack-alt;
                }
            }
        }
    }
}

.made-by {
    a {
        font-family: $font-stack-alt;
    }
}