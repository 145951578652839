/* ======================================== */
/* vacancies */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

.vacancies {
    width: 100%;
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    @include breakpoint(sm) {
        row-gap: 4rem;
    }

    &__body {
        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__body {
        width: 100%;
    }
}

// ------------------------------------------------
// Feed from workable.com - Classes are pre-defined

$whr-gap: 2rem;

.whr {

    &-wrapper {
        width: 100%;
        display: block;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        li {
            margin: 0;
        }
    }

    &-items {
        display: flex;
        flex-direction: column;
        gap: $whr-gap;

        @include breakpoint(sm) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &-item {
        margin: 0;
        padding: 1rem;
        background: rgb(var(--t-bg));
        color: rgb(var(--t-content));
        border-left: 0.5rem solid rgb(var(--t-highlight));
        box-shadow: .25rem .25rem 2rem 0 rgba(0, 0, 0, .1);

        @include breakpoint(sm) {
            width: calc(50% - #{math.div($whr-gap, 2)});
            padding: 2rem;
        }
    }

    &-title {
        font-family: $font-stack-alt;

        a {
            color: rgb(var(--t-link--neutral));
            text-decoration: underline;

            &:hover,
            &:focus {
                color: rgb(var(--t-link));
            }
        }
    }

    &-info {
    }

    &-dept,
    &-location,
    &-date {

        span {
            font-weight: bold;
        }
    }
}