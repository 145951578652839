/* ======================================== */
/* timeline */
/* ======================================== */

@use 'abstracts' as *;

.timeline {
    width: 100%;
    background: rgba(var(--t-bg-test), 0.1);
    margin: 2rem 0;

    @include breakpoint(sm) {
        margin: 4rem 0;
    }

    &__year {
        width: 100%;
        display: flex;
        margin-bottom: 2rem;

        @include breakpoint(sm-max) {
            flex-direction: column;
            align-items: center;
        }

        @include breakpoint(sm) {
            flex-direction: row;
            align-items: flex-start;
            position: relative;
            margin-bottom: 4rem;
        }

        &:last-child {
            margin-bottom: 0;

            .timeline__event:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }

    &__year-heading {
        position: sticky;
        font-size: 3rem;
        font-weight: normal;
        color: rgb(var(--t-content--action));
        line-height: 1;

        @include breakpoint(sm-max) {
            top: 1em;
            display: inline-block;
            z-index: 1;
            background: rgb(var(--t-standout-bg));
            color: rgb(var(--t-standout-content));
            font-family: $font-stack-alt;
            font-size: 0.75rem;
            padding: 0.5em 1em;
            margin: 0;
            letter-spacing: 0.25em;
        }

        @include breakpoint(sm) {
            flex: 0 0 6ch;
            top: 2rem;
            margin: 0;
        }

        @include breakpoint(md) {
            font-size: 2.5rem;
        }

        @include breakpoint(lg) {
            font-size: 5rem;
        }
    }

    &__events {
        width: 100%;

        @include breakpoint(sm-max) {
            margin-top: 1rem;
        }

        @include breakpoint(sm) {
            flex: 1 1 0;
        }
    }

    &__event {
        width: 100%;
        position: relative;
        display: block;
        padding: 2rem 0;
        border-bottom: 1px solid rgb(var(--t-highlight));

        @include breakpoint(xs) {
            display: flex;
            align-items: flex-start;
            flex-direction: row-reverse;
            gap: 2rem;
        }

        &:first-child {
            padding-top: 0;
        }
    }

    &__content {
        flex-grow: 1;

        > *:last-child {
            margin-bottom: 0;
        }

        &-heading {
            font-weight: bold;
        }
    }

    &__media {

        @include breakpoint(xs-max) {
            margin-bottom: 1rem;
        }

        @include breakpoint(xs) {
            float: right;
            margin: 0 0 2rem 1rem;
        }
    }

    &__picture {
        display: block;
    }

    &__image {
        display: block;
    }

}