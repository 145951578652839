/* ======================================== */
/* watermark */
/* ======================================== */

@use 'abstracts' as *;

.watermark {
    position: fixed;
    top: 0;
    right: 0;
    mix-blend-mode: exclusion;
    z-index: 10;
    pointer-events: none;
    opacity: 0.03;
    width: 100vw;
    height: 100vh;

    @include breakpoint(md) {
        width: calc(100vw - #{$sidebar-width});
    }

    > svg {
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        object-fit: contain;
        object-position: center center;
    }
}