// ----------------------------------------
// layout variables
// ----------------------------------------

$sidebar-width: 260px;
$max-content-width: 65rem;

$array-layouts:
   "layout--flush" 
   "layout--gutter"
;

// ----------------------------------------
// site gutters

$layout-gutter--xs: 2rem;
$layout-gutter--sm: 3.5vw;
$layout-gutter--md: 5vw;
$layout-gutter--lg: 7.5vw;
$layout-gutter--mx: 7.5vw;
   // -> as a map for less bloat in queries
   $layout-gutter-sizes: (
      "xs-only": $layout-gutter--xs,
      "sm-only": $layout-gutter--sm,
      "md-only": $layout-gutter--md,
      "lg-only": $layout-gutter--lg,
      "mx-only": $layout-gutter--mx
   );

// ----------------------------------------
// navbar

$navbar-height--xs: 60px;
$navbar-height--sm: 60px;
$navbar-height--md: 60px;
$navbar-height--lg: 60px;
$navbar-height--mx: 60px;
   // -> as a map for less bloat in queries
   $navbar-height-sizes: (
      "xs-only": $navbar-height--xs,
      "sm-only": $navbar-height--sm,
      "md-only": $navbar-height--md,
      "lg-only": $navbar-height--lg,
      "mx-only": $navbar-height--mx
   );

// ----------------------------------------
// content horizontal spacing

$content-block-pillarbox--xs: 2rem;
$content-block-pillarbox--sm: 7.5vw;
$content-block-pillarbox--md: 5vw;
$content-block-pillarbox--lg: 5vw;
$content-block-pillarbox--mx: 5vw;
   // -> as a map for less bloat in queries
   $content-block-pillarbox-sizes: (
      "xs-only": $content-block-pillarbox--xs,
      "sm-only": $content-block-pillarbox--sm,
      "md-only": $content-block-pillarbox--md,
      "lg-only": $content-block-pillarbox--lg,
      "mx-only": $content-block-pillarbox--mx
   );

// ----------------------------------------
// content vertical spacing

$content-block-letterbox--xs: 2rem;
$content-block-letterbox--sm: 5vw;
$content-block-letterbox--md: 6vw;
$content-block-letterbox--lg: 6vw;
$content-block-letterbox--mx: 6vw;

   // -> as a map for less bloat in queries
   $content-block-letterbox-sizes: (
      "xs-only": $content-block-letterbox--xs,
      "sm-only": $content-block-letterbox--sm,
      "md-only": $content-block-letterbox--md,
      "lg-only": $content-block-letterbox--lg,
      "mx-only": $content-block-letterbox--mx
   );
   