/* ======================================== */
/* checklist */
/* ======================================== */

@use 'abstracts' as *;

.check-list {
    font-size: 1rem;
    margin: 2rem 0;

    &__item {
        position: relative;
        list-style: none;
        padding-left: 1.5em;

        &:before {
            content: "";       // This will get same font colour (ios safari fix)
            position: absolute;
            top: 0.5em;
            left: 0;
            width: 0.75em;
            height: 0.375em;
            border-width: 0 0 0.125em 0.125em;
            border-style: solid;
            border-color: $green;
            transform: rotate(-45deg);
            transform-origin: center center;
        }
    }
}