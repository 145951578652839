/* ======================================== */
/* hero-prominent */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

$hero-prominent-dot-size: 1rem;

.hero-prominent {
    width: 100%;
    display: block;

    // Portrait Viewport
    @media (orientation: portrait) {
        // padding-top: math.percentage(math.div(1, 1));
        padding-top: calc(100vh - 90px);

        @include breakpoint(sm) {
            padding-top: math.percentage(math.div(2, 3));
        }
        
        &__inner {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    
    // Landscape Viewport
    @media (orientation: landscape) {
        padding-top: initial;
        height: calc(100vh - #{$layout-gutter--xs});

        @include breakpoint(sm) {
            height: calc(100vh - #{$layout-gutter--sm});
        }
        @include breakpoint(md) {
            height: calc(100vh - #{$layout-gutter--md});
        }
        @include breakpoint(lg) {
            height: calc(100vh - #{$layout-gutter--lg});
        }
        @include breakpoint(mx) {
            height: calc(100vh - #{$layout-gutter--mx});
        }

        &__inner {
            position: relative;
        }
    }

    &__inner {
        width: 100%;
        height: 100%;
    }

    &__controls {
        position: absolute;
        bottom: $hero-prominent-dot-size;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        right: $content-block-pillarbox--xs;

        @each $breakpoint, $size in $content-block-pillarbox-sizes {
            @include breakpoint($breakpoint) {
                right: $size;
            }
        }

        &-dots { // override default slick style of circle dots
            position: relative;
            bottom: auto;
            z-index: 80;
            width: 100%;
            height: auto;
            width: auto;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            li {
                margin: 0 0 0 math.div($hero-prominent-dot-size, 4);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                @include breakpoint(xs) {
                    margin: 0 0 0 math.div($hero-prominent-dot-size, 2);
                }

                &:first-child {
                    margin-left: 0;
                }

                &.slick-active {

                    button {
                        background-color: rgb(var(--t-linkbutton-icon-bg--action));
                    }
                }
            }

            button {
                width: math.div($hero-prominent-dot-size, 2);
                height: math.div($hero-prominent-dot-size, 2);
                border-radius: $hero-prominent-dot-size;
                background-color: transparent;
                border: 1px solid rgb(var(--t-linkbutton-icon-bg--action));
                font-size: 1px;
                text-indent: -99999px;

                @include breakpoint(xs) {
                    width: $hero-prominent-dot-size;
                    height: $hero-prominent-dot-size;
                }
            }
        }
    }
    
    &__carousel {
        width: 100%;
        height: 100%;
        display: grid; // Seems to fix issue with slick slide blowout

        &-item {
            position: relative;
            width: 100%;
            height: 100%;

            &-inner {
                // slick slider attatches its own classes to the item so this ensures we have our own space to play with layout
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    display: none;
                }

                // Modifiers - left and right
                &--left,
                &--right {
                    align-items: flex-end;
                    @extend %content-vertical-spacing;
                    @extend %content-horizontal-spacing;

                    @include breakpoint(sm) {align-items: center;}

                    .hero-prominent__carousel-item-content {
                        width: 100%;
                        @include breakpoint(sm) {width: 50%;}
                        @include breakpoint(md) {width: 62.5%;}
                        @include breakpoint(lg) {width: 50%;}
                        @include breakpoint(mx) {width: 37.5%;}
                    }
                }

                // Modifiers - left
                &--left {
                    justify-content: flex-start;

                    &:before {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0.3) 75%);
                        
                        @include breakpoint(sm) {
                            // It's only really here where we have more space to show something on the right
                            background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0) 75%);
                        }
                    }
                }
                
                // Modifiers - right
                &--right {
                    justify-content: flex-end;

                    &:before {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0.3) 75%);

                        @include breakpoint(sm) {
                            // It's only really here where we have more space to show something on the right
                            background: linear-gradient(270deg, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0) 75%);
                        }
                    }
                }
                
                // Modifiers - bottom
                &--default,
                &--bottom {
                    align-items: flex-end;
                    justify-content: center;

                    &:before {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0.3) 75%);

                        @include breakpoint(sm) {
                            background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0) 75%);
                        }
                    }

                    .hero-prominent__carousel-item-content {
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        @extend %content-vertical-spacing;
                        @extend %content-horizontal-spacing;
                    }
                }
                
                // darkening layer
                &.has-darkening-layer {

                    &:before {
                        display: block;
                    }
                }
            }

            &-media {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &-picture {
                display: block;
                width: 100%;
                height: 100%;
            }

            &-img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform-origin: center;
                transition: transform 3s 0s ease-in-out;
            }

            &-iframevideo {
                top: 0;
                left: 0;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                position: absolute;
                object-fit: cover;

                @media (max-aspect-ratio: 16/9) {
                    height: 100%;
                    padding-left: 177%; // 16:9 ratio
                }

                @media (min-aspect-ratio: 16/9) {
                    padding-top: 56.25%; // 16:9 ratio
                    width: 100%;
                }

                &__iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            &-uploadvideo {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &-content {
                position: relative;
                z-index: 3;
                max-width: $max-content-width;

                &*:first-child {margin-top: 0;}
                &*:last-child {margin-bottom: 0;}
            }

        }


        $hero-carousel-prevnext-size: 2rem;

        &-prev {
            transform: rotate(180deg);
        }

        &-prev,
        &-next {
            // display: none;
            z-index: 3;
            top: 50%;
            transform-origin: center center;
            width: $hero-carousel-prevnext-size;
            background: transparent; // override base button style
            border: none;  // override base button style
            line-height: 0;  // override base button style
            padding: 0;  // override base button style
            transition: all 0.3s 0s ease-in-out;

            @include breakpoint(xs-max) {
                display: none !important; // override JS
            }

            &.slick-disabled {
                opacity: 0;
            }

            > svg {
                width: 100%;

                .theme-icon-bg {
                    fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                    transition: fill 0.3s 0s ease-out;
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content));
                    transition: fill 0.3s 0s ease-out;
                }
                .theme-icon-content-stroke {
                    fill: rgb(var(--t-linkbutton-icon-content));
                    transition: stroke 0.3s 0s ease-out;
                }
            }

            &:hover,
            &:focus {

                > svg {
    
                    .theme-icon-bg {
                        fill: rgb(var(--t-linkbutton-icon-bg--action));
                    }
                    .theme-icon-content-fill {
                        fill: rgb(var(--t-linkbutton-icon-content--action));
                    }
                    .theme-icon-content-stroke {
                        fill: rgb(var(--t-linkbutton-icon-content--action));
                    }
                }

            }
        }

    }

    // Slick Slider overrides

    .slick {
        &-list,
        &-track,
        &-slide {
            height: 100%;
        }
    }
}

// Slick Slider overrides
.jsHeroProminentCarousel {
    
    // .hero-prominent__carousel-item-content {
    //     opacity: 0;
    //     transform: translateY(2vh);
    //     transition-property: transform, opacity;
    //     transition-timing-function: ease-in-out;
    //     transition-duration: 1s;
    //     transition-delay: 0s;
    // }
}

@keyframes hero-image-pulse {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}

.is-animating {

    // .hero-prominent__carousel-item-content {
    //     opacity: 1;
    //     transform: translateY(0);
    // }

    // .hero-prominent__carousel-item-img {
    //     animation: hero-image-pulse 11s ease-in-out;
    //     animation-iteration-count: 1;
    //     animation-fill-mode: forwards;
    // }
}


// Make sure first slide is visible stright away. opacity 0 on content above the fold break Larget contentflul paint.
.hero-prominent__carousel-item:first-child {
    [data-animate] {
        opacity: 1;
    }
}
