/* ======================================== */
/* theme */
// Colours only
/* ======================================== */

@use 'sass:color';
@use '../abstracts' as *;

// Update root variables from default white label to custom
// All css variables must be output as 3 RGB properties separated by commas for correct output
// Example: 255, 255, 255

:root {
    // DEFAULT
        // background
        --t-bg:                                                 #{$white_rgb};
            $t-bg--action_rgb: 
                color.red(color.scale($light-blue, $lightness: 75%)), 
                color.green(color.scale($light-blue, $lightness: 75%)), 
                color.blue(color.scale($light-blue, $lightness: 75%));
            --t-bg--action:                                     #{$t-bg--action_rgb};
        // Highlight
        --t-highlight:                                          #{$light-blue_rgb};
        // Content
        --t-content:                                            #{$black_rgb};

            $t-content--action_rgb:
                color.red(color.scale($core-blue, $lightness: -20%)), 
                color.green(color.scale($core-blue, $lightness: -20%)), 
                color.blue(color.scale($core-blue, $lightness: -20%));
            --t-content--action:                                #{$t-content--action_rgb};

        // link
        --t-link:                                               #{$core-blue_rgb};
        --t-link--neutral:                                      #{$black_rgb};

        $t-link--alt-action_rgb:
            color.red(color.scale($core-blue, $lightness: -20%)), 
            color.green(color.scale($core-blue, $lightness: -20%)), 
            color.blue(color.scale($core-blue, $lightness: -20%));
        --t-link--alt-action:                                   #{$t-link--alt-action_rgb};

        $t-link-bg_rgb:
            color.red(color.scale($white, $alpha: -100%)), 
            color.green(color.scale($white, $alpha: -100%)), 
            color.blue(color.scale($white, $alpha: -100%));
        --t-link-bg:                                            #{$t-link-bg_rgb};

            --t-link--action:                                   #{$white_rgb};
            --t-link-bg--action:                                #{$core-blue_rgb};
        // link button - link with icon
        --t-linkbutton:                                         #{$black_rgb};
        --t-linkbutton-icon-bg:                                 #{$black_rgb};
        --t-linkbutton-icon-content:                            #{$black_rgb};
            --t-linkbutton--action:                             #{$core-blue_rgb};
            --t-linkbutton-icon-bg--action:                     #{$core-blue_rgb};
            --t-linkbutton-icon-content--action:                #{$white_rgb};
        // button
        --t-btn-bg:                                             #{$core-blue_rgb};
        --t-btn-border:                                         #{$light-blue_rgb};
        --t-btn-content:                                        #{$white_rgb};
            --t-btn-bg--action:                                 #{$core-blue_rgb};
            --t-btn-border--action:                             #{$light-blue_rgb};
            --t-btn-content--action:                            #{$core-blue_rgb};
        // icon
        --t-icon-bg:                                            #{$core-blue_rgb};
        --t-icon-content:                                       #{$white_rgb};

        // mask
        --t-mask:                                               #{$white_rgb};

    // STANDOUT
        --t-standout-bg:                                        #{$core-blue_rgb};

        $t-standout-bg--action_rgb:
            color.red(color.scale($core-blue, $lightness: -20%)), 
            color.green(color.scale($core-blue, $lightness: -20%)), 
            color.blue(color.scale($core-blue, $lightness: -20%));
        --t-standout-bg--action:                                #{$t-standout-bg--action_rgb};

        --t-standout-content:                                   #{$white_rgb};
        // link button - link with icon
        --t-standout-linkbutton:                                         #{$white_rgb};
        --t-standout-linkbutton-icon-bg:                                 #{$white_rgb};
        --t-standout-linkbutton-icon-content:                            #{$white_rgb};
            --t-standout-linkbutton--action:                             #{$light-blue_rgb};
            --t-standout-linkbutton-icon-bg--action:                     #{$white_rgb};
            --t-standout-linkbutton-icon-content--action:                #{$core-blue_rgb};

    // FORM
        --t-form-input-bg:                                      #{$white_rgb};
        --t-form-input-content:                                 #{$black_rgb};

        $t-form-input-border_rgb:
            color.red(color.scale($black, $lightness: 90%)), 
            color.green(color.scale($black, $lightness: 90%)), 
            color.blue(color.scale($black, $lightness: 90%));
        --t-form-input-border:                                  #{$t-form-input-border_rgb};

            --t-form-input-bg--action:                          #{$white_rgb};
            --t-form-input-content--action:                     #{$black_rgb};
            --t-form-input-border--action:                      #{$black_rgb};

}

  
// ========================================
// Re-define colour relationships per theme
// ========================================


// ----------------------------------------
// white theme

.theme-bg-white {
    --bg-color: #{$white_rgb};
}
.theme-white {
    // DEFAULT
        // background
        --t-bg:                                                 #{$white_rgb};

            $t-bg--action_rgb:
                color.red(color.scale($light-blue, $lightness: 75%)), 
                color.green(color.scale($light-blue, $lightness: 75%)), 
                color.blue(color.scale($light-blue, $lightness: 75%));
            --t-bg--action:                                     #{$t-bg--action_rgb};
        // Highlight
        --t-highlight:                                          #{$light-blue_rgb};
        // Content
        --t-content:                                            #{$black_rgb};
            --t-content--action:                                #{$core-blue_rgb};
        // link
        --t-link:                                               #{$core-blue_rgb};
        --t-link--neutral:                                      #{$black_rgb};

        $t-link--alt-action_rgb:
            color.red(color.scale($core-blue, $lightness: -20%)), 
            color.green(color.scale($core-blue, $lightness: -20%)), 
            color.blue(color.scale($core-blue, $lightness: -20%));
        --t-link--alt-action:                                   #{$t-link--alt-action_rgb};

        --t-link-bg:                                            #{$white_rgb};
            --t-link--action:                                   #{$white_rgb};
            --t-link-bg--action:                                #{$core-blue_rgb};
        // link button - link with icon
        --t-linkbutton:                                         #{$black_rgb};
        --t-linkbutton-icon-bg:                                 #{$black_rgb};
        --t-linkbutton-icon-content:                            #{$black_rgb};
            --t-linkbutton--action:                             #{$core-blue_rgb};
            --t-linkbutton-icon-bg--action:                     #{$core-blue_rgb};
            --t-linkbutton-icon-content--action:                #{$white_rgb};
        // button
        --t-btn-bg:                                             #{$core-blue_rgb};
        --t-btn-border:                                         #{$light-blue_rgb};
        --t-btn-content:                                        #{$white_rgb};
            --t-btn-bg--action:                                 #{$core-blue_rgb};
            --t-btn-border--action:                             #{$light-blue_rgb};
            --t-btn-content--action:                            #{$core-blue_rgb};
        // icon
        --t-icon-bg:                                            #{$core-blue_rgb};
        --t-icon-content:                                       #{$white_rgb};

        // mask
        --t-mask:                                               #{$white_rgb};

    // STANDOUT
        --t-standout-bg:                                        #{$core-blue_rgb};

        $t-standout-bg--action_rgb:
            color.red(color.scale($core-blue, $lightness: -20%)), 
            color.green(color.scale($core-blue, $lightness: -20%)), 
            color.blue(color.scale($core-blue, $lightness: -20%));
        --t-standout-bg--action:                                #{$t-standout-bg--action_rgb};

        --t-standout-content:                                   #{$white_rgb};
        // link button - link with icon
        --t-standout-linkbutton:                                         #{$white_rgb};
        --t-standout-linkbutton-icon-bg:                                 #{$white_rgb};
        --t-standout-linkbutton-icon-content:                            #{$white_rgb};
            --t-standout-linkbutton--action:                             #{$light-blue_rgb};
            --t-standout-linkbutton-icon-bg--action:                     #{$white_rgb};
            --t-standout-linkbutton-icon-content--action:                #{$core-blue_rgb};

    // FORM
        --t-form-input-bg:                                      #{$white_rgb};
        --t-form-input-content:                                 #{$black_rgb};

        $t-form-input-border_rgb:
            color.red(color.scale($black, $lightness: 90%)), 
            color.green(color.scale($black, $lightness: 90%)), 
            color.blue(color.scale($black, $lightness: 90%));
        --t-form-input-border:                                  #{$t-form-input-border_rgb};

            --t-form-input-bg--action:                          #{$white_rgb};
            --t-form-input-content--action:                     #{$black_rgb};
            --t-form-input-border--action:                      #{$black_rgb};
}


// ----------------------------------------
// off-white theme

.theme-bg-off-white {
    --bg-color: #{$white};
}
.theme-off-white {
    // DEFAULT
        // background
        --t-bg:                                                 #{$off-white_rgb};
            --t-bg--action:                                     #{$white_rgb};
        // Highlight
        --t-highlight:                                          #{$light-blue_rgb};
        // Content
        --t-content:                                            #{$black_rgb};
            --t-content--action:                                #{$core-blue_rgb};
        // link
        --t-link:                                               #{$core-blue_rgb};
        --t-link--neutral:                                      #{$black_rgb};

        $t-link--alt-action_rgb:
            color.red(color.scale($core-blue, $lightness: -20%)), 
            color.green(color.scale($core-blue, $lightness: -20%)), 
            color.blue(color.scale($core-blue, $lightness: -20%));
        --t-link--alt-action:                                   #{$t-link--alt-action_rgb};

        --t-link-bg:                                            #{$white_rgb};
            --t-link--action:                                   #{$white_rgb};
            --t-link-bg--action:                                #{$core-blue_rgb};
        // link button - link with icon
        --t-linkbutton:                                         #{$black_rgb};
        --t-linkbutton-icon-bg:                                 #{$black_rgb};
        --t-linkbutton-icon-content:                            #{$black_rgb};
            --t-linkbutton--action:                             #{$core-blue_rgb};
            --t-linkbutton-icon-bg--action:                     #{$core-blue_rgb};
            --t-linkbutton-icon-content--action:                #{$white_rgb};
        // button
        --t-btn-bg:                                             #{$white_rgb};
        --t-btn-border:                                         #{$light-blue_rgb};
        --t-btn-content:                                        #{$core-blue_rgb};
            --t-btn-bg--action:                                 #{$white_rgb};
            --t-btn-border--action:                             #{$light-blue_rgb};
            --t-btn-content--action:                            #{$core-blue_rgb};
        // icon
        --t-icon-bg:                                            #{$core-blue_rgb};
        --t-icon-content:                                       #{$white_rgb};

        // mask
        --t-mask:                                               #{$off-white_rgb};

    // STANDOUT
        --t-standout-bg:                                        #{$core-blue_rgb};

        $t-standout-bg--action_rgb:
            color.red(color.scale($core-blue, $lightness: -20%)), 
            color.green(color.scale($core-blue, $lightness: -20%)), 
            color.blue(color.scale($core-blue, $lightness: -20%));
        --t-standout-bg--action:                                #{$t-standout-bg--action_rgb};

        --t-standout-content:                                   #{$white_rgb};
        // link button - link with icon
        --t-standout-linkbutton:                                         #{$white_rgb};
        --t-standout-linkbutton-icon-bg:                                 #{$white_rgb};
        --t-standout-linkbutton-icon-content:                            #{$white_rgb};
            --t-standout-linkbutton--action:                             #{$light-blue_rgb};
            --t-standout-linkbutton-icon-bg--action:                     #{$white_rgb};
            --t-standout-linkbutton-icon-content--action:                #{$core-blue_rgb};

    // FORM
        --t-form-input-bg:                                      #{$white_rgb};
        --t-form-input-content:                                 #{$black_rgb};

        $t-form-input-border_rgb:
            color.red(color.scale($black, $lightness: 90%)), 
            color.green(color.scale($black, $lightness: 90%)), 
            color.blue(color.scale($black, $lightness: 90%));
        --t-form-input-border:                                  #{$t-form-input-border_rgb};

            --t-form-input-bg--action:                          #{$white_rgb};
            --t-form-input-content--action:                     #{$black_rgb};
            --t-form-input-border--action:                      #{$black_rgb};
        
}


// ----------------------------------------
// core blue theme

.theme-bg-core-blue {
    --bg-color: #{$white_rgb};
}
.theme-core-blue {
    // DEFAULT
        // background
        --t-bg:                                                 #{$core-blue_rgb};

            $t-bg--action_rgb:
                color.red(color.scale($core-blue, $lightness: -15%)), 
                color.green(color.scale($core-blue, $lightness: -15%)), 
                color.blue(color.scale($core-blue, $lightness: -15%));
            --t-bg--action:                                     #{$t-bg--action_rgb};
        // Highlight
        --t-highlight:                                          #{$light-blue_rgb};
        // Content
        --t-content:                                            #{$white_rgb};

            $t-content--action_rgb:
                color.red(color.scale($light-blue, $lightness: 50%)), 
                color.green(color.scale($light-blue, $lightness: 50%)), 
                color.blue(color.scale($light-blue, $lightness: 50%));
            --t-content--action:                                #{$t-content--action_rgb};
        // link
        $t-link_rgb:
            color.red(color.scale($light-blue, $lightness: -50%)), 
            color.green(color.scale($light-blue, $lightness: -50%)), 
            color.blue(color.scale($light-blue, $lightness: -50%));
        --t-link:                                               #{$t-link_rgb};

        --t-link--neutral:                                      #{$white_rgb};
        --t-link--alt-action:                                   #{$light-blue_rgb};
        --t-link-bg:                                            #{$white_rgb};
            --t-link--action:                                   #{$core-blue_rgb};
            --t-link-bg--action:                                #{$white_rgb};
        // link button - link with icon
        --t-linkbutton:                                         #{$white_rgb};
        --t-linkbutton-icon-bg:                                 #{$white_rgb};
        --t-linkbutton-icon-content:                            #{$white_rgb};
            --t-linkbutton--action:                             #{$white_rgb};
            --t-linkbutton-icon-bg--action:                     #{$white_rgb};
            --t-linkbutton-icon-content--action:                #{$core-blue_rgb};
        // button
        --t-btn-bg:                                             #{$white_rgb};
        --t-btn-border:                                         #{$light-blue_rgb};
        --t-btn-content:                                        #{$core-blue_rgb};
            --t-btn-bg--action:                                 #{$white_rgb};
            --t-btn-border--action:                             #{$light-blue_rgb};
            --t-btn-content--action:                            #{$core-blue_rgb};
        // icon
        --t-icon-bg:                                            #{$core-blue_rgb};
        --t-icon-content:                                       #{$white_rgb};

        // mask
        --t-mask:                                               #{$core-blue_rgb};

    // STANDOUT
        --t-standout-bg:                                        #{$white_rgb};

        $t-standout-bg--action_rgb:
            color.red(color.scale($light-blue, $lightness: 50%)), 
            color.green(color.scale($light-blue, $lightness: 50%)), 
            color.blue(color.scale($light-blue, $lightness: 50%));
        --t-standout-bg--action:                                #{$t-standout-bg--action_rgb};

        --t-standout-content:                                   #{$core-blue};
        // link button - link with icon
        --t-standout-linkbutton:                                         #{$black_rgb};
        --t-standout-linkbutton-icon-bg:                                 #{$black_rgb};
        --t-standout-linkbutton-icon-content:                            #{$black_rgb};
            --t-standout-linkbutton--action:                             #{$core-blue_rgb};
            --t-standout-linkbutton-icon-bg--action:                     #{$core-blue_rgb};
            --t-standout-linkbutton-icon-content--action:                #{$white_rgb};

    // FORM
        $t-form-input-bg_rgb:
            color.red(color.scale($core-blue, $lightness: -15%)), 
            color.green(color.scale($core-blue, $lightness: -15%)), 
            color.blue(color.scale($core-blue, $lightness: -15%));
        --t-form-input-bg:                                      #{$t-form-input-bg_rgb};

        --t-form-input-content:                                 #{$white_rgb};
        --t-form-input-border:                                  #{$white_rgb};

            $t-form-input-bg--action_rgb:
                color.red(color.scale($core-blue, $lightness: -30%)), 
                color.green(color.scale($core-blue, $lightness: -30%)), 
                color.blue(color.scale($core-blue, $lightness: -30%));
            --t-form-input-bg--action:                          #{$t-form-input-bg--action_rgb};

            --t-form-input-content--action:                     #{$white_rgb};
            --t-form-input-border--action:                      #{$white_rgb};
}


// ----------------------------------------
// red theme

.theme-bg-red {
    --bg-color: #{$white_rgb};
}
.theme-red {
    // DEFAULT
        // background
        --t-bg:                                                 #{$red_rgb};

            $t-bg--action_rgb:
                color.red(color.scale($red, $lightness: -15%)), 
                color.green(color.scale($red, $lightness: -15%)), 
                color.blue(color.scale($red, $lightness: -15%));
            --t-bg--action:                                     #{$t-bg--action_rgb};
        // Highlight
        --t-highlight:                                          #{$light-blue_rgb};
        // Content
        --t-content:                                            #{$white_rgb};

            $t-content--action_rgb:
                color.red(color.scale($light-blue, $lightness: 50%)), 
                color.green(color.scale($light-blue, $lightness: 50%)), 
                color.blue(color.scale($light-blue, $lightness: 50%));
            --t-content--action:                                #{$t-content--action_rgb};
        // link
        $t-link_rgb:
            color.red(color.scale($light-blue, $lightness: -50%)), 
            color.green(color.scale($light-blue, $lightness: -50%)), 
            color.blue(color.scale($light-blue, $lightness: -50%));
        --t-link:                                               #{$t-link_rgb};

        --t-link--neutral:                                      #{$white_rgb};
        --t-link--alt-action:                                   #{$light-blue_rgb};
        --t-link-bg:                                            #{$white_rgb};
            --t-link--action:                                   #{$red_rgb};
            --t-link-bg--action:                                #{$white_rgb};
        // link button - link with icon
        --t-linkbutton:                                         #{$white_rgb};
        --t-linkbutton-icon-bg:                                 #{$white_rgb};
        --t-linkbutton-icon-content:                            #{$white_rgb};
            --t-linkbutton--action:                             #{$white_rgb};
            --t-linkbutton-icon-bg--action:                     #{$white_rgb};
            --t-linkbutton-icon-content--action:                #{$red_rgb};
        // button
        --t-btn-bg:                                             #{$white_rgb};
        --t-btn-border:                                         #{$light-blue_rgb};
        --t-btn-content:                                        #{$core-blue_rgb};
            --t-btn-bg--action:                                 #{$white_rgb};
            --t-btn-border--action:                             #{$light-blue_rgb};
            --t-btn-content--action:                            #{$core-blue_rgb};
        // icon
        --t-icon-bg:                                            #{$red_rgb};
        --t-icon-content:                                       #{$white_rgb};

        // mask
        --t-mask:                                               #{$red_rgb};

    // STANDOUT
        --t-standout-bg:                                        #{$white_rgb};

        $t-standout-bg--action_rgb:
            color.red(color.scale($light-blue, $lightness: 50%)), 
            color.green(color.scale($light-blue, $lightness: 50%)), 
            color.blue(color.scale($light-blue, $lightness: 50%));
        --t-standout-bg--action:                                #{$t-standout-bg--action_rgb};

        --t-standout-content:                                   #{$red_rgb};
        // link button - link with icon
        --t-standout-linkbutton:                                         #{$black_rgb};
        --t-standout-linkbutton-icon-bg:                                 #{$black_rgb};
        --t-standout-linkbutton-icon-content:                            #{$black_rgb};
            --t-standout-linkbutton--action:                             #{$red_rgb};
            --t-standout-linkbutton-icon-bg--action:                     #{$red_rgb};
            --t-standout-linkbutton-icon-content--action:                #{$white_rgb};

    // FORM
        $t-form-input-bg_rgb:
            color.red(color.scale($red, $lightness: -15%)), 
            color.green(color.scale($red, $lightness: -15%)), 
            color.blue(color.scale($red, $lightness: -15%));
        --t-form-input-bg:                                      #{$t-form-input-bg_rgb};

        --t-form-input-content:                                 #{$white_rgb};
        --t-form-input-border:                                  #{$white_rgb};

            $t-form-input-bg--action_rgb:
                color.red(color.scale($red, $lightness: -30%)), 
                color.green(color.scale($red, $lightness: -30%)), 
                color.blue(color.scale($red, $lightness: -30%));
            --t-form-input-bg--action:                          #{$t-form-input-bg--action_rgb};

            --t-form-input-content--action:                     #{$white_rgb};
            --t-form-input-border--action:                      #{$white_rgb};
}

// ----------------------------------------
// imagery theme - used when the background is an image

.theme-bg-imagery {
    --bg-color: #{$black_rgb};
}
.theme-imagery {
    // DEFAULT
        // background
        --t-bg:                                                 #{$black_rgb};

            $t-bg--action_rgb:
                color.red(color.scale($black, $lightness: -15%)), 
                color.green(color.scale($black, $lightness: -15%)), 
                color.blue(color.scale($black, $lightness: -15%));
            --t-bg--action:                                     #{$t-bg--action_rgb};
        // Highlight
        --t-highlight:                                          #{$light-blue_rgb};
        // Content
        --t-content:                                            #{$white_rgb};
            --t-content--action:                                #{$white_rgb};
        // link
        $t-link_rgb:
            color.red(color.scale($light-blue, $lightness: -50%)), 
            color.green(color.scale($light-blue, $lightness: -50%)), 
            color.blue(color.scale($light-blue, $lightness: -50%));
        --t-link:                                               #{$t-link_rgb};

        --t-link--neutral:                                      #{$white_rgb};
        --t-link--alt-action:                                   #{$light-blue_rgb};
        --t-link-bg:                                            #{$white_rgb};
            --t-link--action:                                   #{$core-blue_rgb};
            --t-link-bg--action:                                #{$white_rgb};
        // link button - link with icon
        --t-linkbutton:                                         #{$white_rgb};
        --t-linkbutton-icon-bg:                                 #{$white_rgb};
        --t-linkbutton-icon-content:                            #{$white_rgb};
            --t-linkbutton--action:                             #{$white_rgb};
            --t-linkbutton-icon-bg--action:                     #{$white_rgb};
            --t-linkbutton-icon-content--action:                #{$core-blue_rgb};
        // button
        --t-btn-bg:                                             #{$white_rgb};
        --t-btn-border:                                         #{$light-blue_rgb};
        --t-btn-content:                                        #{$core-blue_rgb};
            --t-btn-bg--action:                                 #{$white_rgb};
            --t-btn-border--action:                             #{$light-blue_rgb};
            --t-btn-content--action:                            #{$core-blue_rgb};
        // icon
        --t-icon-bg:                                            #{$black_rgb};
        --t-icon-content:                                       #{$white_rgb};

    // STANDOUT
        --t-standout-bg:                                        #{$white_rgb};

        $t-standout-bg--action_rgb:
            color.red(color.scale($white, $lightness: -10%)), 
            color.green(color.scale($white, $lightness: -10%)), 
            color.blue(color.scale($white, $lightness: -10%));
        --t-standout-bg--action:                                #{$t-standout-bg--action_rgb};

        --t-standout-content:                                   #{$black_rgb};
        // link button - link with icon
        --t-standout-linkbutton:                                         #{$black_rgb};
        --t-standout-linkbutton-icon-bg:                                 #{$black_rgb};
        --t-standout-linkbutton-icon-content:                            #{$black_rgb};
            --t-standout-linkbutton--action:                             #{$core-blue_rgb};
            --t-standout-linkbutton-icon-bg--action:                     #{$core-blue_rgb};
            --t-standout-linkbutton-icon-content--action:                #{$white_rgb};
}

// ========================================
// Theme Highlight
// ========================================

.highlight-red {
    // Highlight
    --t-highlight:                                          #{$red_rgb};
    --t-highlight-bg:                                       #{$red_rgb};
    --t-highlight-content:                                  #{$white_rgb};
    // link
    --t-link:                                               #{$red_rgb};

    $t-link--alt-action_rgb:
        color.red(color.scale($red, $lightness: -20%)), 
        color.green(color.scale($red, $lightness: -20%)), 
        color.blue(color.scale($red, $lightness: -20%));
    --t-link--alt-action:                                   #{$t-link--alt-action_rgb};

    --t-link-bg:                                            #{$red_rgb};
        --t-link--action:                                   #{$white_rgb};
        --t-link-bg--action:                                #{$red_rgb};// link button - link with icon
    // link button
    --t-linkbutton-icon-bg--action:                         #{$red_rgb};
    --t-linkbutton-icon-content--action:                    #{$white_rgb};
    // button
    --t-btn-border:                                         #{$red_rgb};
        --t-btn-border--action:                             #{$red_rgb};
        --t-btn-content--action:                            #{$white_rgb};
    // icon
    --t-icon-bg:                                            #{$red_rgb};
    --t-icon-content:                                       #{$white_rgb};
    // standout
    --t-standout-linkbutton-icon-bg--action:                #{$red_rgb};
    --t-standout-linkbutton-icon-content--action:           #{$white_rgb};
}

.highlight-green {
    // Highlight
    --t-highlight:                                          #{$green_rgb};
    --t-highlight-bg:                                       #{$green_rgb};
    --t-highlight-content:                                  #{$core-blue_rgb};
    // link
    --t-link:                                               #{$green_rgb};

    $t-link--alt-action_rgb:
        color.red(color.scale($green, $lightness: -20%)), 
        color.green(color.scale($green, $lightness: -20%)), 
        color.blue(color.scale($green, $lightness: -20%));
    --t-link--alt-action:                                   #{$t-link--alt-action_rgb};
    
    --t-link-bg:                                            #{$green_rgb};
        --t-link--action:                                   #{$core-blue_rgb};
        --t-link-bg--action:                                #{$green_rgb};
    // link button
    --t-linkbutton-icon-bg--action:                         #{$green_rgb};
    --t-linkbutton-icon-content--action:                    #{$core-blue_rgb};
    // button
    --t-btn-border:                                         #{$green_rgb};
        --t-btn-border--action:                             #{$green_rgb};
        --t-btn-content--action:                            #{$core-blue_rgb};
    // icon
    --t-icon-bg:                                            #{$green_rgb};
    --t-icon-content:                                       #{$core-blue_rgb};
    // standout
    --t-standout-linkbutton-icon-bg--action:                #{$green_rgb};
    --t-standout-linkbutton-icon-content--action:           #{$core-blue_rgb};
}
.highlight-yellow {
    // Highlight
    --t-highlight:                                          #{$yellow_rgb};
    --t-highlight-bg:                                       #{$yellow_rgb};
    --t-highlight-content:                                  #{$core-blue_rgb};
    // link
    --t-link:                                               #{$yellow_rgb};

    $t-link--alt-action_rgb:
        color.red(color.scale($yellow, $lightness: -20%)), 
        color.green(color.scale($yellow, $lightness: -20%)), 
        color.blue(color.scale($yellow, $lightness: -20%));
    --t-link--alt-action:                                   #{$t-link--alt-action_rgb};

    --t-link-bg:                                            #{$yellow_rgb};
        --t-link--action:                                   #{$core-blue_rgb};
        --t-link-bg--action:                                #{$yellow_rgb};
    // link button
    --t-linkbutton-icon-bg--action:                         #{$yellow_rgb};
    --t-linkbutton-icon-content--action:                    #{$core-blue_rgb};
    // button
    --t-btn-border:                                         #{$yellow_rgb};
        --t-btn-border--action:                             #{$yellow_rgb};
        --t-btn-content--action:                            #{$core-blue_rgb};
    // icon
    --t-icon-bg:                                            #{$yellow_rgb};
    --t-icon-content:                                       #{$core-blue_rgb};
    // standout
    --t-standout-linkbutton-icon-bg--action:                #{$yellow_rgb};
    --t-standout-linkbutton-icon-content--action:           #{$core-blue_rgb};
}
.highlight-light-blue {
    // Highlight
    --t-highlight:                                          #{$light-blue_rgb};
    --t-highlight-bg:                                       #{$light-blue_rgb};
    --t-highlight-content:                                  #{$core-blue_rgb};
    // link
    --t-link:                                               #{$light-blue_rgb};

    $t-link--alt-action_rgb:
        color.red(color.scale($light-blue, $lightness: -20%)), 
        color.green(color.scale($light-blue, $lightness: -20%)), 
        color.blue(color.scale($light-blue, $lightness: -20%));
    --t-link--alt-action:                                   #{$t-link--alt-action_rgb};

    --t-link-bg:                                            #{$light-blue_rgb};
        --t-link--action:                                   #{$core-blue_rgb};
        --t-link-bg--action:                                #{$light-blue_rgb};
    // link button
    --t-linkbutton-icon-bg--action:                         #{$light-blue_rgb};
    --t-linkbutton-icon-content--action:                    #{$core-blue_rgb};
    // button
    --t-btn-border:                                         #{$light-blue_rgb};
        --t-btn-border--action:                             #{$light-blue_rgb};
        --t-btn-content--action:                            #{$core-blue_rgb};
    // icon
    --t-icon-bg:                                            #{$light-blue_rgb};
    --t-icon-content:                                       #{$core-blue_rgb};
    // standout
    --t-standout-linkbutton-icon-bg--action:                #{$light-blue_rgb};
    --t-standout-linkbutton-icon-content--action:           #{$core-blue_rgb};
}

.highlight-core-blue {
    // Highlight
    --t-highlight:                                          #{$core-blue_rgb};
    --t-highlight-bg:                                       #{$core-blue_rgb};
    --t-highlight-content:                                  #{$white_rgb};
    // link
    --t-link:                                               #{$core-blue_rgb};

    $t-link--alt-action_rgb:
        color.red(color.scale($core-blue, $lightness: -20%)), 
        color.green(color.scale($core-blue, $lightness: -20%)), 
        color.blue(color.scale($core-blue, $lightness: -20%));
    --t-link--alt-action:                                   #{$t-link--alt-action_rgb};

    --t-link-bg:                                            #{$core-blue_rgb};
        --t-link--action:                                   #{$white_rgb};
        --t-link-bg--action:                                #{$core-blue_rgb};
    // link button
    --t-linkbutton-icon-bg--action:                         #{$core-blue_rgb};
    --t-linkbutton-icon-content--action:                    #{$white_rgb};
    // button

    $t-btn-border_rgb:
        color.red(color.scale($core-blue, $lightness: -20%)), 
        color.green(color.scale($core-blue, $lightness: -20%)), 
        color.blue(color.scale($core-blue, $lightness: -20%));
    --t-btn-border:                                         #{$t-btn-border_rgb};

        $t-btn-border--action_rgb:
            color.red(color.scale($core-blue, $lightness: -20%)), 
            color.green(color.scale($core-blue, $lightness: -20%)), 
            color.blue(color.scale($core-blue, $lightness: -20%));
        --t-btn-border--action:                             #{$t-btn-border--action_rgb};

        --t-btn-content--action:                            #{$white_rgb};
    // icon
    --t-icon-bg:                                            #{$core-blue_rgb};
    --t-icon-content:                                       #{$white_rgb};
    // standout
    --t-standout-linkbutton-icon-bg--action:                #{$core-blue_rgb};
    --t-standout-linkbutton-icon-content--action:           #{$white_rgb};
}

.highlight-white {
    // Highlight
    --t-highlight:                                          #{$white_rgb};
    --t-highlight-bg:                                       #{$white_rgb};
    --t-highlight-content:                                  #{$core-blue_rgb};
    // link
    --t-link:                                               #{$white_rgb};

    $t-link--alt-action_rgb:
        color.red(color.scale($white, $lightness: -10%)), 
        color.green(color.scale($white, $lightness: -10%)), 
        color.blue(color.scale($white, $lightness: -10%));
    --t-link--alt-action:                                   #{$t-link--alt-action_rgb};

    --t-link-bg:                                            #{$white_rgb};
        --t-link--action:                                   #{$core-blue_rgb};
        --t-link-bg--action:                                #{$white_rgb};
    // link button
    --t-linkbutton-icon-bg--action:                         #{$white_rgb};
    --t-linkbutton-icon-content--action:                    #{$core-blue_rgb};
    // button
    $t-btn-border_rgb:
        color.red(color.scale($white, $lightness: -10%)), 
        color.green(color.scale($white, $lightness: -10%)), 
        color.blue(color.scale($white, $lightness: -10%));
    --t-btn-border:                                         #{$t-btn-border_rgb};

        $t-btn-border--action_rgb:
            color.red(color.scale($white, $lightness: -10%)), 
            color.green(color.scale($white, $lightness: -10%)), 
            color.blue(color.scale($white, $lightness: -10%));
        --t-btn-border--action:                             #{$t-btn-border--action_rgb};

        --t-btn-content--action:                            #{$core-blue_rgb};
    // icon
    --t-icon-bg:                                            #{$white_rgb};
    --t-icon-content:                                       #{$core-blue_rgb};
    // standout
    --t-standout-linkbutton-icon-bg--action:                #{$white_rgb};
    --t-standout-linkbutton-icon-content--action:           #{$core-blue_rgb};
}

.highlight-black {
    // Highlight
    --t-highlight:                                          #{$black_rgb};
    --t-highlight-bg:                                       #{$black_rgb};
    --t-highlight-content:                                  #{$white_rgb};
    // link
    --t-link:                                               #{$black_rgb};

    $t-link--alt-action_rgb:
        color.red(color.scale($black, $lightness: 20%)), 
        color.green(color.scale($black, $lightness: 20%)), 
        color.blue(color.scale($black, $lightness: 20%));
    --t-link--alt-action:                                   #{$t-link--alt-action_rgb};

    --t-link-bg:                                            #{$black_rgb};
        --t-link--action:                                   #{$white_rgb};
        --t-link-bg--action:                                #{$black_rgb};
    // link button
    --t-linkbutton-icon-bg--action:                         #{$black_rgb};
    --t-linkbutton-icon-content--action:                    #{$white_rgb};
    // button
    --t-btn-border:                                         #{$black_rgb};
        --t-btn-border--action:                             #{$black_rgb};
        --t-btn-content--action:                            #{$white_rgb};
    // icon
    --t-icon-bg:                                            #{$black_rgb};
    --t-icon-content:                                       #{$white_rgb};
    // standout
    --t-standout-linkbutton-icon-bg--action:                #{$black_rgb};
    --t-standout-linkbutton-icon-content--action:           #{$white_rgb};
}


// ==========================================================
// Site specific
// ==========================================================

