/* ======================================== */
/* Mouse target */
/* ======================================== */

.mouse-target {
    position: fixed;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.05);
    width: 1.5rem; 
    height: 1.5rem; 
    border-radius: 50%;
    mix-blend-mode: difference;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 9999;
    box-shadow: 0 0 2rem 2rem rgba(255, 255, 255, 0);
    transform: translateY(-50%) translateX(-50%);
    transition-property: width, height, background, border, box-shadow;
    transition-duration: 0.3s;
    transition-delay: 0s;
    transition-timing-function: ease-out;

    &.active {
        background: rgba(255, 255, 255, 0);
        width: 6rem;
        height: 6rem;
        border-color: rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 2rem 2rem rgba(255, 255, 255, 0.05);
    }

    @media(hover: none) and (pointer: coarse) {
        display: none;
    }
}