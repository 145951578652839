/* ======================================== */
/* map */
/* ======================================== */

@use 'abstracts' as *;

.map {
    width: 100%;
    position: relative;
    background: var(--t-bg);

    @include breakpoint(md) {
        display: flex;
        align-items: flex-start;
    }

    &__map {
        width: 100%;
        display: block;
        height: 100vw; // 16:9

        @include breakpoint(md) {
            flex: 1 1 0;
            height: 100vh;
            max-height: 56.25vw;
            position: sticky;
            top: 0;
        }
    }

    &__content {
        width: 100%;
        display: block;

        @include breakpoint(md) {
            flex: 1 1 0;
            max-width: 50ch;
            position: sticky;
            top: 0;
        }

        &-inner {
            position: relative;
            padding: 4rem 2rem;
            width: 100%;
            margin: 0 auto;
        }

    }

    &__list {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;

        @include breakpoint(md) {
            flex-direction: column;
            flex-wrap: no-wrap;
        }

        &-item {
            flex: 1 0 100%;
            display: flex;
            align-items: flex-start;
            gap: 1rem;

            @include breakpoint(xs) {
                flex-basis: 45%;
            }

            &-icon {
                flex-shrink: 0;
                flex-grow: 0;
                width: 2rem;
            }

            &-content {
                flex-grow: 1;

                > *:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

// --------------------
// Google map specific 

.gmap-mixed {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.info-bubble {
    padding: 1rem;

    > *:last-child {
        margin-bottom: 0;
    }

    h4 {
        font-size: 1rem;
    }

    p {
        font-size: 0.75rem
    }
}

.gm-style {
    background: #8ab4f8; // Standard Google maps sea colour for when the map is mega zoomed out, to avoid a grey box
}