/* ======================================== */
/* pagination-list */
/* ======================================== */

.pagination-list {

    ul {
        display: flex;
        justify-content: center;
        gap: 1px;
        padding: 0;
        list-style: none;

        li {
            margin: 0;
            transition: all 0.2s ease-in-out;

            &:first-child a {
                border-radius: 0.25em 0 0 0.25em;
            }

            &:last-child a {
                border-radius: 0 0.25em 0.25em 0;
            }

            a {
                background: rgba(var(--t-linkbutton-icon-bg), 0.05);
                color: rgb(var(--t-linkbutton-icon-content));
                text-decoration: none;
                padding: 0.5rem 0.75rem;
                display: block;
            }

            &.active, &:hover, &:focus {
                a {
                    background: rgb(var(--t-linkbutton-icon-bg--action));
                    color: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }
    }
}