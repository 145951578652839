/* ======================================== */
/* Signpost */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';
@use 'sass:color';

.case-study-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(430px,1fr));
    gap: 2rem;
    align-items: stretch;
}

// Case: Logo image
.case-study-card {
    position: relative;
    display: flex !important;
    background-color: $white;
    color: $black;
    // border: solid 1px;
    // border-color: rgb(0, 0, 0, 10%);
    transition: all 0.3s 0s ease-out !important; // Overwrite slider transition-opacity only
    box-sizing: border-box;
    overflow: hidden;
    margin-right: 1rem;
    min-height: 330px;

    &:before {
        content: "";
        position: absolute;
        z-index: 20;
        top: 0;
        left: -1px; 
        width: 0;
        height: 100%;
        transition: width 0.3s 0s ease-out;
        background-color: rgb(var(--t-highlight));
    }

    &:hover,
    &:focus {
        box-shadow: 0.25rem 0.25rem 1rem 0 rgb(0, 0, 0, 25%);
        transform: translateY(-3px);
        color: $black;

        &:before {
            width: 0.5rem;
        }

        .case-study-card__icon {
            > svg {
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }
    }

    &__inner {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-end;
        padding: 2rem;
        z-index: 10;
    }

    &__title {
        font-size: 0.75rem;
        text-align: right;
        margin-top: 0;
        margin-bottom: auto;
        color: rgb(var(--t-content), 0.5);
    }

    &__text {
        font-weight: bold;
        margin: 1.5rem 0 1rem 0;
    }

    &__icon {
        display: block;
        width: 2rem;
        align-self: flex-end;

        > img,
        > svg {
            width: 100%;
        }

        > svg {
            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-in-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-in-out;
            }
        }
    }

    &__logo {
        display: block;
        max-width: 200px;
        height: auto;
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        inset: 0;
    }
}

// Case: Cover image
.case-study-card--cover-image {
    color: $white;
    font-family: $font-stack-alt;

    &:after {
        position: absolute;
        content: "";
        inset: 0;
        display: block;
        background-color: rgb(25, 25, 25, 45%);
        z-index: 2;
    }

    .case-study-card__title {
        color: $white;
    }

    .case-study-card__image {
        transition: all 0.45s 0s ease-in;
    }

    .case-study-card__icon {

        > svg {
            .theme-icon-bg {
                fill: rgb(255, 255, 255, 25%);
            }
            .theme-icon-content-stroke {
                stroke: $white;
            }
            .theme-icon-content-fill {
                fill: $white;
            }
        }
    }

    &:hover,
    &:focus {
        color: $white;
        transform: translateY(0px);

        .case-study-card__image {
            transform: scale(1.045);
        }
    }
}

//----------------------------------------
// slider
$article-slider-dot-size: 1rem;
.case-study-slider {
    min-width: 0; // Fix for slick slider
    min-height: 0; // Fix for slick slider
    margin-left: 0;
    margin-bottom: 1rem;
    width: calc(100% + 1rem);

    &__item {
        margin: 0 1rem;
    }

    &__controls {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        margin-top: 1rem;


        &-dots { // override default slick style of circle dots
            position: relative;
            bottom: auto;
            z-index: 80;
            width: 100%;
            height: auto;
            width: auto;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            li {
                margin: 0 0 0 math.div($article-slider-dot-size, 4);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                @include breakpoint(xs) {
                    margin: 0 0 0 math.div($article-slider-dot-size, 2);
                }

                &:first-child {
                    margin-left: 0;
                }

                &.slick-active {

                    button {
                        background-color: rgb(var(--t-linkbutton-icon-bg--action));
                    }
                }
            }

            button {
                width: math.div($article-slider-dot-size, 2);
                height: math.div($article-slider-dot-size, 2);
                border-radius: $article-slider-dot-size;
                background-color: transparent;
                border: 1px solid rgb(var(--t-linkbutton-icon-bg--action));
                font-size: 1px;
                text-indent: -99999px;

                @include breakpoint(xs) {
                    width: $article-slider-dot-size;
                    height: $article-slider-dot-size;
                }
            }
        }

        $articles-slider-prevnext-size: 2rem;

        &-prev {
            transform: rotate(180deg);
        }

        &-prev,
        &-next {
            // display: none;
            z-index: 3;
            top: 50%;
            transform-origin: center center;
            width: $articles-slider-prevnext-size;
            background: transparent; // override base button style
            border: none;  // override base button style
            line-height: 0;  // override base button style
            padding: 0;  // override base button style
            transition: all 0.3s 0s ease-in-out;

            &.slick-disabled {
                opacity: 0;
            }

            > svg {
                width: 100%;

                .theme-icon-bg {
                    fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                    transition: fill 0.3s 0s ease-out;
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content));
                    transition: fill 0.3s 0s ease-out;
                }
                .theme-icon-content-stroke {
                    fill: rgb(var(--t-linkbutton-icon-content));
                    transition: stroke 0.3s 0s ease-out;
                }
            }

            &:hover,
            &:focus {

                > svg {
    
                    .theme-icon-bg {
                        fill: rgb(var(--t-linkbutton-icon-bg--action));
                    }
                    .theme-icon-content-fill {
                        fill: rgb(var(--t-linkbutton-icon-content--action));
                    }
                    .theme-icon-content-stroke {
                        fill: rgb(var(--t-linkbutton-icon-content--action));
                    }
                }

            }
        }
    }

    // Slick overrides - Site specific: You may or may not need or want these
    .slick-track {
        margin-left: 0; // left align cards when there aren't enough to fill out width
    }

    .slick-list {
        overflow: visible !important;
    }

    .slick-slide {
        opacity: 0.25;
        transition: opacity 0.5s 0s ease-in-out;

        &.slick-active {
            opacity: 1;
        }
    }
}


.slider-wrapper {
    display: grid;
    overflow: hidden;
}