/* ======================================== */
/* figure-video */
/* ======================================== */

.figure-video {
    position: relative;
    width: 100%;
    margin: 3rem 0;

    &__figcaption {
        text-align: center;
        padding-top: 0.5em;
        opacity: 0.75;
        font-size: 0.875em;
        font-style: italic;
        color: rgb(var(--t-content));
    }
}
