/* ======================================== */
/* body */
/* ======================================== */

@use 'abstracts' as *;

body, html {
    background: rgb(var(--t-bg));
}


html {

    &.popout-is-active,
    &.search-is-active {
        overflow: hidden;
    }
}